@import "../../core/responsive/responsive.scss";

.fts-vendor-header {
  min-width: 100vw;
  background-color: #ce1b3b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 96px;

  .register-button {
    background-color: rgb(255 255 255);
    color: #ce1b3b;
    padding: 0 1.75rem 0 1.75rem;
    border-radius: 0.25rem;
    min-height: 24px;
    line-height: 64px;
  }
  .register-button:hover {
    color: #ce1b3b;
  }

  a {
    color: white;
    text-decoration: none;

    font-size: 16px;
    line-height: 20px;
  }

  a:hover {
    color: white;
  }

  .fts-vendor-header-navigation {
    display: flex;
    list-style-type: none;
    margin-bottom: 0;

    li:not(:last-child) {
      margin-right: 40px;
    }
  }

  .fts-vendor-header-auth {
    display: flex;
    align-items: center;

    a {
      font-weight: 500;
    }

    .search-button {
      display: flex;
      align-items: center;
      border: 1px solid white;
      background-color: white;
      height: 48px;
      color: #ce1b3b;
      padding: 14px 32px;
      border-radius: 4px;
      font-weight: normal;
    }
  }

  @media (max-width: 1191px) {
    .fts-vendor-header-navigation {
      display: none;
    }
    .fts-vendor-header-auth {
      display: none;
    }
  }
}

.fts-vendor-header-mobile {
  position: sticky !important;
  top: 0;
  z-index: 1000;

  .navbar-brand {
    // padding: 15px 0px;
    display: flex;
    align-items: center;
    margin-top: 1.35rem;

    .brand-logo {
      //background: url("../../../img/logo/vuesax-logo.png") no-repeat;
      background-position: -65px -54px;
      height: 24px;
      width: 35px;
    }

    .brand-text {
      color: #fff;
      padding-left: 1rem;
      font-weight: 600;
      letter-spacing: 0.01rem;
      font-size: 1.1rem;
      animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
        fadein;
    }
  }
}

@include target-breakpoint-and-smaller(sm) {
  .fts-vendor-header {
    img {
      margin-left: 1.5rem !important;
    }

    .mobile-sidebar-action-icon {
      margin-right: 1.5rem;
    }
  }
}
