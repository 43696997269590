.FTS-requested-access-empty-state {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px;
  background: #ffffff;
  box-shadow: 0px 3px 9px #ededf3;
  max-width: 600px;
  text-align: center;

  &-header {

    font-weight: 600;
    font-size: 29px;
    line-height: 32px;
    padding-top: 40px;
    padding-bottom: 16px;
    max-width: 400px;
  }

  &-message {
    max-width: 300px;
  }

  svg {
    stroke: none;
  }
}

@media (max-width: 800px) {
  .FTS-requested-access-empty-state {
    padding: 32px;

    &-header {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
}
