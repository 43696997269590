@import "../../core/responsive/responsive.scss";

.fts-contact-form-modal {
  .fts-contact-form-modal-body {
    padding: 1.5rem 30px 30px 40px;

    .fts-contact-form-modal-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {

        font-weight: bold;
        font-size: 29px;
        line-height: 32px;
      }

      .close-btn {
        height: 28px;
        width: 28px;
        margin-bottom: 4rem;
        background-color: transparent;
      }
    }

    .fts-contact-form-modal-subheading {
      margin-top: -1.2rem;

      font-size: 16px;
      line-height: 18px;
      margin-bottom: 40px;
      color: #32383a;
    }
  }
}

@include target-breakpoint-and-smaller(sm) {
  .fts-contact-form-modal {
    .modal-body {
      padding: 24px;
      .row {
        margin-top: 0 !important;
      }
    }
    .fts-contact-form-modal-body {
      padding: 0px;

      .fts-contact-form-modal-heading {
        h2 {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 8px;
        }

        .close-btn {
          margin-bottom: 8px;
        }
      }

      .fts-contact-form-modal-subheading {
        margin-top: 0;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 0;
        color: #32383a;
        margin-bottom: 24px;
      }
    }
  }
}
