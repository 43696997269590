.fts-badge {

  width: max-content;
  height: 20px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0.5rem !important;
  font-size: 12px !important;
  border-radius: 23px !important;
  line-height: 14px !important;
  color: #fff;
}
