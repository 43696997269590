.invoices-temp-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #32383a;

  .fts-icon {
    margin-bottom: 40px;
    stroke: none;
    width: 320px;
    height: 128px;
  }

  h2 {

    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  p {

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #32383a;
    margin-bottom: 40px;
  }
}

@media (max-width: 1270px) {
  .invoices-temp-empty-state {
    padding: 24px;
  }
}
