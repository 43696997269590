@import "../core/responsive/responsive.scss";

.fts-thank-you {
  height: calc(100vh - 96px);
  background-color: #F3F6F9;

  &-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F6F9;

    &-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 578px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 3px 9px 0 #EDEDF3;
      padding: 40px;
      gap: 40px;

      .fts-icon {
        stroke: none;
        height: 160px;
        max-width: 498px;
        width: 100%;
      }

      h3 {
        margin-bottom: 8px;
        color: #32383A;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
      }

      p {
        margin-bottom: 0;
        color: #32383A;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }

      .btn-secondary {
        color: #32383A;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height:  19.2px;
        height: 48px;
      }
    }
  }
}

@include target-breakpoint-and-smaller(sm) {
  .fts-thank-you {
    height: 100%;

    &-message {
      align-items: start;
      &-box {
        padding: 24px;

        .fts-icon {
          height: 176px;
          width: 100%;
        }

        h3 {
          font-size: 32px;
          line-height: 38.4px;
        }

        p {
          font-size: 20px;
          line-height: 26px;
        }

        .btn-secondary {
          font-size: 14px;
          line-height: 17px;
          height: 48px;
        }
      }
    }
  }
}
