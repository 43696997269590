@import "../core/responsive/responsive";

.upgrade-proof-wrapper {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 24px;
  padding-bottom: 24px;
  margin-top: 24px;

  .fts-listing-item {
    min-width: unset;
  }
}

.animate-expand {
  transition: all 0.3s linear;
}

.animate-expand > td {
  transition: all 0.3s ease-in-out;
}

.expander {
  cursor: pointer;
  height: 100%;
  width: 100px;
  padding: 5px;
  background-color: white !important;
  position: sticky;
  right: -20px;
}

.expander > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

th {
  width: 200px;
  max-width: 350px;
}

.btn-approve {
  background-color: #ce1b3b !important;
  color: white !important;
}

.btn-reject {
  > svg {
    stroke: #ce1b3b;
    fill: #ce1b3b;
  }

  border: 1px solid #ce1b3b !important;
  color: #ce1b3b !important;
  background-color: white !important;
}

.btn-reject:hover,
.btn-reject:active,
.btn-reject:focus {
  border-color: #ce1b3b !important;
  box-shadow: 0 8px 25px -8px #ce1b3b;
  color: #ce1b3b !important;
}

.btn-warning {
  font-size: 14px !important;
  line-height: 24px !important;
}
.btn-approve:hover,
.btn-approve:active,
.btn-approve:focus {
  border-color: #ce1b3b !important;
  box-shadow: 0 8px 25px -8px #ce1b3b;
  color: white !important;
}

.MuiTableCell-root {
  input[type="checkbox"] {
    margin-top: 4px;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1px solid #7d8c91;
  }
}

.custom-proofs-container {
  padding-right: 14px;
  padding-left: 14px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  .actions-wrapper {
    display: flex !important;
  }
}

@media (min-width: 1200px) {
  .custom-proofs-container {
    max-width: 1222px !important;
  }
}

@include target-breakpoint-and-smaller(sm) {
  .custom-proofs-container {
    padding: 0 !important;
    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 24px !important;
      margin-bottom: 12px !important;
    }

    .title-header-wrapper {
      margin-left: -1rem;
    }

    .actions-wrapper {
      flex-direction: column;
      gap: 0 !important;
    }

    .proof-type-picker {
      padding: 0 24px;
    }

    .choose-your-plan-container-header {
      display: none;
    }

    .title-row {
      border-top: none !important;
    }

    .btn-primary {
      width: 100%;
    }

    .proof-row {
      width: calc(100vw + 14px);

      .col-12 {
        padding-right: 0 !important;
      }

      .controlledSwitch {
        margin-top: -3rem;
      }
    }

    .listing-item-wrapper {
      width: calc(100vw - 32px) !important;
    }
    .listing-item-wrapper {
      border: 0 !important;
      width: calc(100vw - 32px) !important;
      overflow-x: hidden;

      .fts-listing-item {
        box-shadow: none !important;
        padding: 0 !important;
        width: calc(100vw - 32px) !important;

        .fts-listing-item-small-buttons {
          width: calc(100vw - 32px) !important;
          padding-left: 0;
        }

        .image-and-description {
          width: calc(100vw - 48px) !important;
        }
      }

      .fts-listing-item-info {
        padding-left: 0 !important;
      }
    }

    .animate-expand {
      height: 450px !important;
    }
  }

  .custom-upgrade-proofs-container-footer {
    &-content {
      gap: 16px;
    }
    padding: 24px !important;
    span {
      top: 0 !important;
      left: 0 !important;
      white-space: nowrap;
    }

    div {
      flex-direction: column;
      width: 100%;
    }
    .container {
      padding: 0 !important;

      .btn-reject {
        margin: 0 !important;
      }
    }
  }
}
