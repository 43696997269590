.FTS-skeleton-empty-state {
  min-height: 355px;
  background: url("../../../img/svg/reports-skeleton.svg") no-repeat 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
  margin: 24px 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding: 24px 0px;
  }
}

.revenue-chart-calculator {
  min-height: 285px;
}
