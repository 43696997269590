.listing-proof-modal-body {
  // min-height: 70vh;

  .listing-proof-modal-body-wrapper {
    padding: 2rem;
    padding-right: 2rem;

    .listing-proof-modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      h2 {
        font-size: 29px;
        line-height: 32px;
        font-weight: bold;
      }
    }
  }
}

.fts-send-listing-proof-context {
  position: absolute;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e5e8e9;
  min-width: 200px;
  border-radius: 4px;
  bottom: 100px;
  right: 30px;
  width: 270px;

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f1f5fa;
    height: 48px;
    border-radius: 4px;
    margin-bottom: 24px;
  }

  .tab {
    height: 40px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    flex: 1;
    font-weight: normal !important;

    font-size: 14px;
    line-height: 16px;
    margin-left: 4px;
    margin-right: 4px;
    outline: none;
  }

  .tab:focus {
    outline: 0 !important;
  }

  .selected {
    border: none;
    background-color: #ffffff;
    color: #242d34;
    box-shadow: 0px 2px 5px #cdd8e6;
  }

  .unselected {
    background-color: transparent;
    border: none;
    color: #7d8c91;
  }
}

.payment-link-button-arrow {
  margin-left: 1rem;
  margin-right: -12px;
}
.opened svg {
  transition: all 0.4s ease;
}

.closed svg {
  transition: all 0.4s ease;
}

.fts-listing-proof-confrimation-modal-cta {
  padding: 0.5rem 2rem;
  width: 100%;

  .checked  {
    margin-right: 12px;
    align-items: center;

    .fts-icon {
      path {
        stroke: #CE1B3B !important;
      }
    }
  }

  .proof-revised-button {
    color: #CE1B3B !important;
  }

  .proof-revised-button-checked {
    background-color: #fad7da !important;
  }

  .proof-revised-checkbox {
    display: flex;
    align-items: center;
    margin-left: auto;

    font-size: 14px;
    list-style: 16px;
    color: #32383a;
    margin-bottom: 3px;

    .checkbox-label {
      margin-left: 0.5rem;
    }

    .no-select {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .checkbox-label:hover {
      margin-left: 0.5rem;
    }
  }
}
