.fts-footer {
  padding: 80px 0 80px 0;
  background-color: #2c2f36;

  ul {
    padding-left: 1.5rem;
    margin-top: 0.5rem;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .options {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .label {
    padding-top: 0.95rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e6e6f5;
  }

  .text-white {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    list-style: none;

    a {
      color: #fff !important;
    }
  }

  svg {
    width: 300px;
    height: 40px;
    stroke: none;
  }

  &-wrapper {
    display: flex;
    flex-direction: row !important;
  }

  .options-right-responsive {
    display: block !important;
  }

  &-left {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    width: max-content;
    padding-right: 147px;
    margin-right: 127px;
    .text-white {
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300 !important;
    }
    p {
      margin-top: auto;
      margin-bottom: auto;
      padding: 0 4px 0 4px;
      color: #fff;
    }

    .terms {
      display: flex;
    }
  }

  &-info {
    margin-top: 46px;
    margin-bottom: 65px;

    .text-white {
      margin: 0;
      padding: 0;
    }
  }
}

@media (max-width: 991px) {
  .fts-footer {
    padding: 52px 24px !important;
    .container {
      padding: 0;
    }
    flex-direction: column;

    &-wrapper {
      width: 100%;
      flex-direction: column !important;
    }

    &-left {
      margin-right: 0;
      padding-right: 0;
      width: 100%;

      .terms {
        .text-white {
         font-size: 14px !important;
          margin-top: 16px;
        }
        flex-direction: column;
        p {
          visibility: hidden;
        }
      }
    }

    &-info {
      padding-top: 40px;
      width: 100%;
      margin-top: 10px;
    }
  }
  .fts-footer-left {
    border: none;
  }

  .options {
    border-bottom: 1px solid gray !important;
    padding-bottom: 16px !important;
    padding-top: 3rem !important;
  }

  .options-right {
    column-gap: 5rem;
    .text-white {
      font-size: 14px !important;
    }

  }

  .options-inline {
    margin-top: 40px;
    display: flex !important;
  }

  .column {
    ul {
      padding: 0;
    }
  }

  .options {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
