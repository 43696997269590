.change-password-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 39px 32px 0px 0px;
  align-items: center;

  &-left {
    padding-left: 26px;
    border-left: 6px solid #ce1b3b;
    height: 24px;
    display: flex;
    align-items: center;

    font-size: 24px;
    font-weight: 600;
    color: #32383a;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
}
