@import "../../core/responsive/responsive";

.update-history-wrapper {
  .notification-wrapper {
    padding: 32px;
    border-bottom: 1px solid #e5e8e9;
  }

  .update-history-date {
    position: relative;

    .range-picker {
      position: absolute;
      top: -66px;
      right: 70px;
    }
  }

  .notification-header {
    display: flex;
    justify-content: space-between;
    padding: "5px 0px";
  }

  .notification-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
  }

  .update-history-table-wrapper {
    padding: 2rem;

    table {
      width: 100%;
      table-layout: fixed;

      thead {
        tr {
          td {

            font-weight: 500 !important;
            font-size: 13px;
            line-height: 18px;
            color: #7d8c91;
          }
        }
      }
    }
  }

  .form-control-position {
    z-index: 100;
  }

  .form-control {
    border-radius: 3px;
  }

  .range {
    width: 270px;
    border-radius: 1px;
    height: 41px;

    .clear-calendar-button {
      transition: 3000ms color ease;
      svg {
        stroke: #ccc;
      }
    }

    .clear-calendar-button:hover {
      svg {
        stroke: #999;
      }
    }

    .form-control-position {
      z-index: 3;
    }

    .fts-date-picker {
      height: 48px !important;
      background-color: #fbfcfd !important;
    }
  }

  .flatpickr-input {
    background-color: transparent !important;
  }
}

@include target-breakpoint-and-smaller("sm") {
  .update-history-wrapper {
    width: 100vw;
    flex: 1;

    .notification-wrapper {
      border-bottom: none;
      padding-bottom: 0px !important;
    }

    .notification-actions {
      padding-bottom: 24px;
      border-bottom: 1px solid #e5e8e9;
    }

    .update-history-date {
      .range-picker {
        right: unset;
        width: 100%;
        padding: 0px 24px;
      }
    }

    .range {
      width: 100%;
    }

    .notifications-wrapper {
      overflow: unset !important;
      max-height: unset !important;
    }

    .notification-header {
      flex-direction: column;
    }

    .notification-action {
      padding-top: 12px;
    }

    .icon-wrapper-read {
      border: 1px solid #e5e8e9;
      border-radius: 4px;
    }
  }
}
