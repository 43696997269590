@import "../core/responsive/responsive";

.FTS-choose-account-page {
  &-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .FTS-choose-account-wrapper {
    padding-bottom: 32px !important;
    .FTS-choose-account-container {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .FTS-account-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 16px;

      .FTS-account-single-option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #E5E8E9;
        padding: 24px;
        max-height: 95px;

        .title {
          color: #32383A;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 4px;
        }

        .description {
          color: #647074;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.2px;
        }
      }

      .radio-button {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        border: 1px solid #E5E8E9;
        background-color: transparent;
      }

      .radio-button-active {
        border: 2px solid #00B5F4;
        display: flex;
        align-items: center;
        justify-content: center;

        .radio-button-active-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #00B5F4;
        }
      }

      .FTS-account-single-option-active {
        padding: 23px;
        border: 2px solid #00B5F4;

        .custom-control-label::before {
          border: 2px solid #00B5F4;
          background-color: transparent;
        }
      }
    }
  }

  .FTS-choose-account-footer {
    .btn-primary {
      color: #FFF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.2px;
      height: 48px !important;
    }
  }
}

@include target-breakpoint-and-smaller("sm") {
  .FTS-choose-account-page {
    .FTS-choose-account-wrapper {
      height: unset;
      margin-top: 0;

      .FTS-choose-account-container {
        margin: unset !important;
      }
    }
    .FTS-choose-account-footer {
      background-color: #fff;
      width: 100vw !important;
      position: sticky !important;
      bottom: 0;
      box-shadow: 0px -3px 9px 0px #EDEDF3;
      display: flex;
      padding: 16px 24px;
      align-items: center;
    }
  }
}
