.fts-rejected-read-reason-modal {
  .modal-content {
    padding: 20px;
    padding-bottom: 0.25rem;
  }
}
.fts-rejected-reason-list-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fts-rejected-modal-message {
  padding: 1rem;
}
.fts-rejected-reason-list-modal-input {
  margin-top: 2rem;
  margin-left: 0rem;
  margin-right: 0rem;
}


.btn-reject-modal {
  color: white !important;
  background-color: #F36565 !important;
}

.btn-reject-modal:hover, .btn-reject-modal:active, .btn-reject-modal:focus {
  border-color: #F36565 !important;
  box-shadow: 0 8px 25px -8px #F36565;
  color: white !important;
}
