.register-state-picker {
  .MuiSelect-select.MuiSelect-select {
    color: white !important;
    margin-top: 7px;
  }
  .MuiInput-underline::before {
    border-bottom: 1px solid white;
  }

  .MuiSelect-icon {
    color: white;
  }

  .MuiSelect-select:focus {
    background-color: transparent !important;
  }

  .MuiInput-underline:hover {
    border-color: white;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 2px solid white;
  }

  .MuiInput-underline::after {
    border-color: white;
  }

  label {
    color: white !important;
  }
}
