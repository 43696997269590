.benefits-modal {
  padding: 24px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    span {
      font-size: 24px;
      line-height: 28px;

      color: #32383a;
      font-weight: 600;
    }
  }

  &-body {
    &-message {
      font-size: 16px;
      line-height: 18px;
      color: #32383a;
    }

    &-benefit {
      display: flex;
      align-items: center;
      &-icon {
        margin-right: 16px;
      }

      &-text {
        width: 100%;
        padding: 18px 0px;
      }
    }

    &-actions {
      padding-top: 24px;
    }

    &-action {
      margin-top: 24px;
    }
  }

  .benefits-modal-body-benefit {
    &:not(:last-of-type) {
      .benefits-modal-body-benefit-text {
        border-bottom: 1px solid #e5e8e9;
      }
    }
  }
}
