.chartSubheading {
  position: relative;
  padding-top: 26px;
}

.chartSubheadingFlex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  > h3 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 43px !important;
    color: #2c2f36 !important;
  }
}

.redUnderline {
  position: absolute;
  width: 48px;
  height: 4px;
  bottom: 40px;
  background: #ce1b3b;
  @apply bg-theme;
}

.chartSubheading h2 {
  font-size: 32px;
  line-height: 42px;
  @apply text-black-100;
}

.chartSubheading p {
  //font-size: 15px;
  //line-height: 20px;
  //margin-top: 31px;


  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 21.5px;
}

@media (max-width: 600px) {
  .chartSubheading {
    padding: 20px 2px 0px 20px;

    .chartSubheadingFlex > h3 {
      font-size: 20px !important;
      line-height: 24px !important;
    }
  }

  .redUnderline {
    top: 90px;
  }
}
