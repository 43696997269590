.payment-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}

.payment-overview {
  overflow: auto;
  height: auto;

  .ag-grid-table {
    height: unset;
    margin-top: -2.5rem;
    margin-left: 0rem;
    margin-right: 0rem;
    .ag-row {
      background-color: transparent;
    }

    .ag-row-selected {
      background-color: #f1fbff;
    }
  }

  .preview-invoice-button {
    display: flex;
    svg {
      margin-right: 0.5rem;
    }
  }

  .download-invoice-button {
    svg {
      stroke: #00b5f4;
      margin-right: 0.5rem;
    }
  }
}

.payment-overview-temp {
  height: max-content;
  width: 1012px !important;

  .card {
    border-radius: 2px;
  }
}

.payment-temporary-link-container {
  margin: 0 40px 2rem 40px;
  display: flex;
  justify-content: center;

  .p-25 {
    padding: 40px !important;
  }
}

.text-end {
  .ag-header-cell-text {
    width: 100%;
    text-align: end;
  }
}

.ag-header-cell-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #647074;
}

.p-25 {
  padding: 40px !important;
}

.payment-total-box {
  .total {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    text-align: right;
    /* Color/Gray/800 */
    color: #32383a;
  }
}

.invoices-date-filter {
  .fts-title-row {
    margin-bottom: 0px !important;
    margin-left: -40px !important;

    .col {
      padding-left: 34px;
    }

    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.edit-payment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32383a !important;
  height: 48px;
  span {
    color: #32383a !important;
  }
  svg {
    stroke: #32383a !important;
  }
}

.invoice-overview-footer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 0.5rem;

  .promo-code-input-field {
    width: 200px;
    input:disabled {
      border: 1px solid #d0d8e8 !important;
      border-color: #dae1e7 !important;
      height: 48px;
      padding: 0.7rem 0.7rem;
      background-color: #fbfcfd;
    }
  }

  .applied {
    color: #57c990 !important;
    background-color: #e9fef2 !important;
    margin-left: 12px;
  }

  .applied:disabled {
    color: #57c990 !important;
    background-color: #e9fef2 !important;
  }

  .apply-button {
    margin-left: 12px;
    background-color: #dff7ff !important;
    color: #00b5f4 !important;
  }

  .promo-code-error-message {
    color: #f20933;
    font-size: 12px;

    margin-left: 2px;
  }

  .payment-total-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 100%;
    font-size: 16px;
    line-height: 20px;

    .total {
      padding-left: 5px;
      color: #32383a;
      font-weight: bold;
    }
  }
}

.payment-footer {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -2px 16px #eaeaea;
  position: sticky;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  padding: 40px !important;
}

@media (max-width: 1270px) {
  .payment-title {
    font-size: 24px;
    line-height: 28px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .payment-temporary-link-container {
    margin: 0;

    .fts-title-row {
      .col {
        margin-left: 1.1rem !important;
        padding-left: 1.5rem !important;
      }
      h4 {
        font-size: 20px !important;
        line-height: 24px !important;
      }
    }
    padding-bottom: 221px !important;

    .p-25 {
      padding: 24px !important;
    }

    .mt-3 {
      margin-top: 0 !important;
    }
  }

  .payment-overview {
    margin: 0 !important;
  }

  .payment-overview-temp {
    padding: 24px !important;
    padding-right: 0 !important;
  }

  .preview-invoice-button {
    display: none !important;
  }

  .download-invoice-button {
    display: flex !important;
  }

  .payment-link {
    .fts-data-list-sidebar {
      display: none !important;
    }
    .fts-data-list-overlay {
      display: none !important;
    }
  }
}

@media (min-width: 1271px) {
  .download-invoice-button {
    display: none !important;
  }
}
