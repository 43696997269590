// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";
@import "../../core/responsive/responsive";

// Overrides user variable
@import "../../core/variables/components-variables";

$icons-path: "~ag-grid-community/src/styles/ag-theme-material/icons/" !default;

$font-family: inherit;
$font-size: inherit;
$font-weight: inherit;
$secondary-font-family: inherit;
$secondary-font-size: inherit;
$secondary-font-weight: 600;

@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material.scss";

.ag-date-table {
  line-height: 1.6;

  &__time {
    font-size: 11px;
    color: #7d8c91;
  }
}

.ag-theme-material .ag-header,
.ag-theme-material .ag-icon {
  color: #7d8c91;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell,
.ag-theme-material .ag-cell {
  padding-left: 15px;
  padding-right: 15px;
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;

}

.ag-grid-table-alternate {
  .ag-dropdown {
    height: 48px;
    padding: 0.3rem;
    background: transparent;
    border-radius: 4px;
    border: 1px solid $gray-300;
    cursor: pointer;
    .dropdown-menu {
      min-width: 3.5rem;
    }
  }
}

.ag-grid-table {
  height: calc(100vh - 30rem);
  display: flex;
  flex-direction: column;

  > div {
    &:last-of-type {
      flex: 1;
    }
  }

  @media screen and (max-height: 800px) {
    height: 620px;
  }

  .ag-header-cell {
    .ag-cell-label-container {
      .ag-header-icon.ag-filter-icon {
        display: none;
      }
    }
  }

  // style select of column header filter
  .ag-dropdown {
    padding: 0.3rem;
    background: transparent;
    border-radius: 50px;
    border: 1px solid $gray-300;
    cursor: pointer;
    .dropdown-menu {
      min-width: 3.5rem;
    }
  }

  // style select of column header filter
  .ag-filter-select {
    padding: 0.3rem;
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  // style input of column header filter input
  .ag-filter-filter {
    margin-right: 10px;
    margin-left: 10px;
    padding-bottom: 0 !important;
    padding-bottom: 0 !important;
    height: 25px !important;
    margin-top: 10px;
    margin-bottom: 14px !important;
  }

  // remove extra padding of input in inline edit
  .ag-input-text-wrapper {
    input {
      padding-bottom: 0 !important;
    }
  }

  .ag-cell-inline-editing {
    height: 62px !important;
  }

  .ag-cell-inline-editting,
  .ag-popup-editor {
    padding-bottom: 12px;
  }

  .ag-grid-table-actions-right {
    @media screen and (max-width: 614px) {
      flex-grow: 1;
    }
  }

  .ag-grid-table-actions-left {
    @media screen and (max-width: 614px) and (min-width: 443px) {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  // remove horizontal scroolbar from pinned columns
  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer {
    overflow-x: auto;
  }

  // Change material theme icon to vuesax theme color
  // .ag-icon-checkbox-checked {
  //   color: $primary;
  // }

  .ag-icon-checkbox-checked-readonly {
    color: $secondary;
  }

  // .ag-icon-checkbox-indeterminate {
  //   color: $primary;
  // }

  .ag-icon-checkbox-indeterminate-readonly {
    color: $secondary;
  }

  // Pagination Styling
  // .ag-paging-panel {
  //   display: block !important;
  //   align-items: center;
  //   height: 105px;
  //   padding: 0;
  //   .ag-paging-row-summary-panel {
  //     display: none;
  //   }
  //   .ag-paging-page-summary-panel {
  //     justify-content: center;
  //     margin-left: 0;
  //     margin-top: 12px;

  //     [ref="lbCurrent"],
  //     [ref="lbTotal"] {
  //       color: $white;
  //       padding: 0.7rem 0.95rem;
  //       border-radius: 0.5rem;
  //     }
  //   }
  //   .ag-paging-button {
  //     background-color: $gray-300;
  //     border-radius: 50%;
  //     padding: 0.5rem 0rem;
  //     margin: 0 0.5rem;
  //     color: $body-color !important;
  //     .ag-icon {
  //       color: $body-color !important;
  //       opacity: 1 !important;
  //     }
  //     &.ag-disabled {
  //       opacity: 0.5 !important;
  //     }
  //   }
  //   span[ref="lbCurrent"] {
  //     background-color: $primary;
  //   }
  //   span[ref="lbTotal"] {
  //     background-color: $gray-300;
  //     color: $body-color !important;
  //   }
  // }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-300;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: $body-bg;
    border-radius: 20px;
  }
}

.fts-pagination {
  padding-top: 35px;
  display: flex;
  align-items: center;

  li {
    margin: 0 5px;
    &:first-child {
      border: 1px solid #ededed;
    }
    &:last-child {
      border: 1px solid #ededed;
    }

    a {
      display: flex;
      height: 25px;
      text-transform: uppercase;
      font-size: 12px;
      padding: 0 10px;
      justify-content: center;
      align-items: center;
      // background-color: #ededed;
    }

    &.disabled {
      a {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    &.active {
      a {
        background-color: $dark;
        color: #fff !important;
      }
    }

    &.break-me {
      a {
        cursor: default;
      }
    }
  }
}

.agGrid-row-datebox {
  border: 1px solid #ccf0fd;
  padding: 3px 5px;
  width: 140px;
  outline: none;
  border-radius: 3px;

  &:hover {
    background-color: #ecfaff;
    border: 1px solid #b4e1f1;
  }
}

.header-switch {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;

  .header-switch-text {

    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #32383a;
  }
}

.updates-search-input {
  height: 38px;
}

.header-updates-title {
  padding-top: -2px;
}

.header-updates-buttons {
  display: flex;
  margin-right: 1rem;
  padding: 0px;

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #f1f5fa;
    height: 40px;
    margin-left: 1rem;
    border-radius: 4px;
  }

  .tab {
    height: 32px;
    width: 174px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    font-weight: normal !important;

    font-size: 14px;
    line-height: 16px;
    margin-left: 4px;
    margin-right: 4px;
    outline: none;
  }

  .tab:focus {
    outline: 0 !important;
  }

  .selected {
    border: none;
    background-color: #ffffff;
    color: #242d34;
    box-shadow: 0px 2px 5px #cdd8e6;
  }

  .unselected {
    background-color: transparent;
    border: none;
    color: #7d8c91;
  }
}

.btn-secondary {
  color: #8b97a3 !important;
  background-color: transparent !important;
  outline: none;
  border: none;

  &:hover {
    color: #8b97a3 !important;
    outline: none;
    border: none;
  }
  &:focus {
    color: #8b97a3 !important;
    background-color: transparent !important;
    outline: none;
    border: none;
  }
}

.bg-promotion-popover {
  .bg-popover-wrapper {
    display: flex;
    flex-direction: column;
    width: 275px;
  }

  .arrow::before {
    display: none;
  }

  .arrow::after {
    border-bottom-color: #505454 !important;
  }

  .bg-popover {
    padding: 1rem 0 0px;
    display: flex;
    .bg-popover-title {
      color: #fff;
      font-weight: bold;
      line-height: 16px;
      font-size: 13px;

      margin-bottom: 1rem;
      margin-left: 1rem;
    }
    .bg-popover-cat-name {

      line-height: 17px;
      font-size: 13px;
      color: #fff;
      margin: 0;
      padding: 0;
      padding-bottom: 8px;
      margin-bottom: 8px;
      padding-left: 1rem;
    }

    .bg-popover-cat-name:not(:last-child) {
      border-bottom: 1px solid grey;
    }
  }
}

@media screen and (max-width: 576px) {
  .agGrid-card {
    .card-body {
      padding-bottom: 4rem !important;
    }
  }
  .ag-grid-table {
    .ag-paging-panel {
      [ref="btFirst"],
      [ref="btLast"] {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .agGrid-card {
    .card-body {
      padding-bottom: 6.5rem !important;
    }
  }
  .ag-paging-panel {
    [ref="lbCurrent"],
    [ref="lbTotal"] {
      margin: 0 5px !important;
    }
    .ag-paging-button {
      margin: 0 0.3rem !important;
    }
  }
  .export-btn {
    margin-top: 0.5rem;
  }
}
@include target-breakpoint-and-smaller("sm") {
  .pagination {
    margin-bottom: 0;
    margin-left: unset !important;
    min-height: 48px;
  }

  .fts-pagination {
    justify-content: center;
    li {
      display: flex;
      align-items: center;

      a {
        height: 100%;
        line-height: 14px;

        padding: 0 14px;
      }
    }
  }
}
