// ================================================================================================
// 	File Name: authentication.scss
// 	Description: Page content different authentication pages layouts SCSS.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy React Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

@import "../core/responsive/responsive.scss";

// authentication pages background

.bg-theme {
  //--tw-bg-opacity: 1;
  // background-color: rgb(224, 33, 67);
  background-color: #fff;
}

.mt-40 {
  margin-top: 80px;
}

.container,
.position-relative,
.mt-40 {
  > .wrapper {
    max-width: 500px;
    overflow: auto;
    color: #32383a;
    margin: auto;
  }

  > .wrapper h4 {
    font-size: 56px;
    color: #32383a;
  }

  .login-btn {
    position: absolute;
    right: -10px;
    bottom: -40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding: 0;
    z-index: 2;
  }
}

//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus,
//textarea:-webkit-autofill,
//textarea:-webkit-autofill:hover,
//textarea:-webkit-autofill:focus,
//select:-webkit-autofill,
//select:-webkit-autofill:hover,
//select:-webkit-autofill:focus {
//  border: unset;
//  -webkit-text-fill-color: #32383a;
//  // -webkit-box-shadow: 0 0 0px 1000px rgb(224, 33, 67) inset;
//  transition: background-color 5000s ease-in-out 0s;
//}

.loginHeading {
  margin-bottom: 64px;

  h4 {
    font-weight: 600;
    margin-bottom: 1rem !important;
  }
  p {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5rem;
  }
}

.FTS-login-links {
  margin-top: 3rem;
  a {
    color: #191c1d;
    text-decoration: underline;
  }
}

.socialItems {
  .wrapper {
    min-width: max-content;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    margin: 18px 89px;
    display: flex;
    align-items: center;

    color: rgb(125, 140, 145);

    svg {
      stroke: none;
    }
  }

  .wrapper:hover {
    cursor: pointer;
  }

  .label {
    line-height: 20px;

    margin-left: 1rem;
    font-size: 1rem;

    color: rgb(50, 56, 58);
  }
}

.display-grid {
  display: grid;
}

.border-r {
  border-right: 1px rgba(255, 255, 255, 0.8);
  > svg {
    width: 300px;
    height: 40px;
  }
}
.bg-grey {
  background-color: rgb(44, 47, 54);
}
// .MuiInputBase-input {
//   color: white !important;
// }
.col-md-4 {
  ul {
    list-style: none;
  }

  a {
    text-decoration: unset;
    color: white;
  }
}

.socials {
  display: contents;
  background-color: white;

  > .wrapper {
    z-index: 1;
    display: flex;
    align-items: center;
    background-color: #f0f2f7;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .social-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .socialItems {
    display: flex;
  }

  .conditions {
    line-height: 1rem;
    margin-top: 1.5rem;

    font-size: 0.875rem;
    color: rgb(50, 56, 58);
  }

  .conditions:hover {
    cursor: default;
  }

  .conditions a {
    color: rgb(50, 56, 58);
    text-decoration: underline;
  }

  .conditions a:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .socialItems {
      flex-direction: column;
    }
  }
}

.bg-authentication {
  // background: $red url("../../img/pages/map_bg.png");
  background-size: contain;
  opacity: 0.9;

  .login-title {
    margin-top: 1rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
  }

  .auth-title,
  .vx-checkbox-con span,
  .divider .divider-text {
    color: $fts-mid-gray;
  }

  .auth-footer {
    padding: 0rem 1.5rem 0.5rem;

    .footer-btn {
      .btn {
        padding: 0.87rem 1.2rem !important;
        margin: 1rem 1rem 1rem 0;
      }
    }
  }
}

.login-card {
  width: 500px;
}

.login-tabs-container h4 {
  font-size: 34px;
  line-height: 40px;
}

.FTS-services-wrapper > div {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;

  .FTS-services-label {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 42px;
    line-height: 40px;
    color: #fff;
    margin: 60px 0px;

    .FTS-services-label-text {
      display: flex;
      flex-direction: column;
    }

    .FTS-services-label-text span:last-of-type {
      padding-left: 20px;
    }
  }

  .FTS-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 10px;
    max-width: 600px;
  }

  .FTS-single-service {
    min-width: 190px;
    color: #fff;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    span {
      font-size: 19px;
      line-height: 24px;
      margin-left: 12px;
    }
  }
}

// Unlock btn spacing in sm

@media (max-width: 400px) {
  .unlock-btn {
    margin-top: 1rem;
  }
}

@media (max-width: 575px) {
  .socials {
    > .wrapper {
      padding-top: 52px !important;
    }
  }
}

.FTS-robot-check {
  display: flex;
  flex-direction: column;
}

.FTS-robot-check p {
  font-size: 24px;
}

.register-tabs-container {
  min-height: calc(100vh - 23.5rem);
}

.register-tabs-container,
.register-tabs-container h4 {
  color: #32383a;
}

.register-tabs-container h4 {
  font-size: 54px;
  font-weight: 600;
  margin-top: 40px;
}

.register-tabs-container p {
  font-size: 16px;
  line-height: 18px;
}
.FTS-register-btn {
  position: absolute;
  right: -10px;
  bottom: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  padding: 0;
}

.register-form-label-group {
  min-height: 75px;
  margin-bottom: 0;
}

.FTS-Registration-error {
  font-size: 14px;

  color: #C73A3A;
  padding-top: 5px;
}

.login-tabs-container h4 {
  font-size: 34px;
  line-height: 40px;
}

.FTS-services-wrapper > div {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;

  .FTS-services-label {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 42px;
    line-height: 40px;
    color: #32383a;
    margin: 60px 0px;

    .FTS-services-label-text {
      display: flex;
      flex-direction: column;
    }

    .FTS-services-label-text span:last-of-type {
      padding-left: 20px;
    }
  }

  .FTS-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 10px;
    max-width: 600px;
  }

  .FTS-single-service {
    min-width: 190px;
    color: #32383a;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    span {
      font-size: 19px;
      line-height: 24px;
      margin-left: 12px;
    }
  }
}

.forgot-password {
  height: calc(100vh - 7rem);

  .forgot-password-form {
    padding-top: 5rem;
    max-width: 550px;
    color: #32383a;
    .forgot-password-header {
      .back-button {
        display: flex;
        align-items: center;

        color: #32383a;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.04em;
        font-weight: 600;
        margin-bottom: 0.5rem;

        svg {
          margin-right: 0.5rem;
        }
      }

      .back-button:hover {
        cursor: pointer;
      }

      margin-left: 0.9rem;
      h4 {
        font-weight: 600;
        font-size: 54px;
        line-height: 65px;
      }
      p {
        font-size: 1rem;
        line-height: 18px;
        padding-top: 0.5rem;
      }
    }

    .back-to-login-btn {
      color: #191c1d;
      text-decoration: underline;

      font-size: 14px;
      line-height: 20px;
    }
  }

  .forgot-password-bottom {
    position: absolute;
    bottom: 0;
    height: 100px;
    background-color: #f0f2f7;
    width: 100%;
  }

  .change-password-bottom {
    position: absolute;
    bottom: 0;
    height: 100px;
    background-color: #f0f2f7;
    width: 100%;
  }

  .FTS-register-btn {
    right: 5rem;
    bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding: 0;
  }
}

.fts-registered-email {
  margin-top: 56px;
  &-box {
    display: flex;
    align-items: center;
    background-color: #f0f2f7;
    border-radius: 4px;
    margin-top: 16px;
    padding: 21px 19px;
    word-break: break-all;

    svg {
      margin-right: 19px;
    }
  }
}

.change-password {
  height: calc(100vh);

  .change-password-form {
    max-width: 550px;
    .change-password-header {
      display: flex;
      flex-direction: column;

      .back-button {
        display: flex;
        align-items: center;

        color: #32383a;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.04em;
        font-weight: 600;
        margin-bottom: 0.5rem;

        svg {
          margin-right: 0.5rem;
        }
      }

      .back-button:hover {
        cursor: pointer;
      }

      margin-left: 0.9rem;
      h4 {
        font-weight: 600;
        font-size: 54px;
        line-height: 65px;
      }
      p {
        font-size: 1rem;
        line-height: 18px;
        padding-top: 0.5rem;
      }
    }

    .back-to-login-btn {
      color: #191c1d;
      text-decoration: underline;

      font-size: 14px;
      line-height: 20px;
    }
  }

  .change-password-bottom {
    position: absolute;
    bottom: 0;
    height: 100px;
    background-color: #f0f2f7;
    width: 100%;
  }

  .FTS-register-btn {
    right: 5rem;
    bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding: 0;
  }
}

.content-footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.footer-grey {
  background-color: #f0f2f7;
  height: 98px;
  width: 100%;
}

.account-selection-wrapper {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.socials-wrapper {
  margin: 18px 89px;
}

// Unlock btn spacing in sm

@media (max-width: 1500px) {
  .mt-40 {
    margin-top: 4rem !important;
  }

  .mt-40 {
    > .wrapper {
      height: unset;
    }
  }
}

@media (max-width: 575px) {
  .register-tabs-container {
    margin-top: 0px !important;
    padding: 0px 16px 56px 16px !important;

    h4 {
      font-size: 20px !important;
      line-height: 24px !important;
    }

    .FTS-register-btn {
      right: 2rem !important;
    }

    .FTS-robot-check p {
      padding-top: 40px !important;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }

    .FTS-robot-check span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .socials-wrapper {
    margin: 18px 0 !important;
  }
}

@include target-breakpoint-and-smaller("sm") {
  .loginHeading {
    margin-bottom: 24px;

    p {
      margin-bottom: 0;
    }
  }

  .login-form {
    padding-bottom: 45px;
  }

  .forgot-password {
    &-header {
      margin-left: 0 !important;
    }
    .col-sm-8 {
      padding: 0 !important;
    }
    .forgot-password-form {
      padding: 24px;

      h4 {
        font-size: 24px !important;
        line-height: 28px !important;
        letter-spacing: 0em !important;
      }
    }
    .forgot-password-bottom {
      height: unset;
      background-color: unset;
      padding-bottom: 18px;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      .FTS-register-btn {
        position: unset;
        width: 100%;
        height: 48px;
        margin-right: 24px;
        margin-left: 24px;
      }
    }
  }

  .change-password {
    height: calc(100vh);
    .change-password-form {
      margin-top: 0 !important;

      padding: 24px;
      .change-password-header {
        padding: 0 !important;
        margin: 0 !important;
        align-items: flex-start !important;
      }
      h4 {
        font-size: 20px !important;
        line-height: 24px !important;
        letter-spacing: 0em !important;
        margin-bottom: 14px;
      }
      p {
        font-size: 16px !important;
        line-height: 18px !important;
        padding-top: 0 !important;
        color: #32383a !important;
        margin-bottom: 4rem;
      }

      .change-password-form-content {
        padding: 0 !important;
      }

      .FTS-reset-password-form {
        div {
          padding-top: 0 !important;
        }
      }
    }
  }

  .account-selection-wrapper {
    height: unset !important;
    overflow: unset !important;
    display: unset !important;
    flex-direction: unset !important;
  }
}
