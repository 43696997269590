// * Please note that when updating the breakpoints in this file, you also need to update the breakpoints
// * in the /src/data/responsive/breakpoints.json file, and vice versa

// xs lower bound is 0, but it is not needed
$breakpoints-min: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// xxl has no upper bound
$breakpoints-max: (
  xs: 575.98px,
  sm: 767.98px,
  md: 991.98px,
  lg: 1199.98px,
  xl: 1399.98px,
);

@mixin target-only-breakpoint($breakpoint) {
  @if $breakpoint == 'xs' {
    @media screen and (max-width: map-get($breakpoints-max, $breakpoint)) {
      @content;
    }
  } @else if $breakpoint == 'xxl' {
    @media screen and (min-width: map-get($breakpoints-min, $breakpoint)) {
      @content;
    }
  } @else {
    @media screen and (min-width: map-get($breakpoints-min, $breakpoint)) and (max-width: map-get($breakpoints-max, $breakpoint)) {
      @content;
    }
  }
}

@mixin target-breakpoint-and-smaller($breakpoint) {
  @if $breakpoint == 'xxl' {
    // Target everything, no media query needed
    @content;
  } @else {
    @media screen and (max-width: map-get($breakpoints-max, $breakpoint)) {
      @content;
    }
  }
}

@mixin target-breakpoint-and-larger($breakpoint) {
  @if $breakpoint == 'xs' {
    // Target everything, no media query needed
    @content;
  } @else {
    @media screen and (min-width: map-get($breakpoints-min, $breakpoint)) {
      @content;
    }
  }
}

@mixin target-breakpoints-between($lower-breakpoint, $upper-breakpoint) {
  $min-value: map-get($breakpoints-min, $lower-breakpoint);
  $max-value: map-get($breakpoints-max, $upper-breakpoint);

  @if $min-value == null or $max-value == null {
    @error "One of the specified breakpoints is not valid. Please make sure you are using valid lower/upper bounds when using this mixin.";
  }

  @media screen and (min-width: $min-value) and (max-width: $max-value) {
    @content;
  }
}

@mixin target-width-and-smaller($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin target-width-and-larger($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
