@import "../../core/responsive/responsive";

.payment-method-body {
  width: 600px;
}

.modal {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
}

.modal::-webkit-scrollbar {
  display: none;
}

.fts-payment-modal {
  border-radius: 4px;
  .fts-payment-modal::-webkit-scrollbar {
    display: none;
  }

  .fts-payment-modal {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .payment-modal-footer {
    padding: 12px 27px;
  }

  .modal-body {
    padding: 0;
  }

  .void-reason-body {
    padding: 40px 40px 0 40px !important;
  }

  .payment-modal-body {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    color: #32383a;

    .void-reason-title {
      font-size: 14px;
      line-height: 20px;
    }

    .payment-modal-body-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .fts-icon {
      stroke: none;
      margin-right: 12px;
    }

    .payment-method-title {
      display: flex;
      justify-content: space-between;
      padding: 0;
      padding-bottom: 0px;

      .fts-close-btn {
        background-color: transparent;
        color: #7d8c91;
      }

      &-text {
        display: flex;
        align-items: center;
        gap: 8px;

        &-heading {
          margin: 0;
        }
      }
    }

    .payment-modal-title {
      display: flex;
      justify-content: space-between;
      padding: 0;
      padding-bottom: 24px;
      border-bottom: 1px solid #e5e8e9;

      .fts-close-btn {
        background-color: transparent;
        color: #7d8c91;
      }

      .checkmark {
        width: 40px;
        height: 40px;
        margin-right: 15px;
      }

      .checked {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #e6fce2;
        width: 40px;
        height: 40px;
        margin-right: 24px;

        .fts-icon {
          margin-right: 0;
          path {
            stroke-width: 1.5px;
            stroke: #57a14a;
          }
        }
      }
    }

    .border-bottom-none {
      border-bottom: none !important;
    }

    .payment-modal-message {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .payment-error-title {
    display: flex;
    border-bottom: none !important;
    padding-bottom: 0 !important;

    .card-remove-icon {
      margin-right: 12px;
    }

    .payment-error-text {
      color: #424c54;

      font-size: 14px;
      line-height: 20px;

      .ftsPhoneNumber {
        font-weight: bold;
      }
    }

    .under-review-text {
    }
  }

  .payment-method-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 7px;
    gap: 16px;

    .btn-primary {
      svg {
        path {
          fill: transparent !important;
          stroke: #fff !important;
        }
      }
    }

    .btn-secondary {
      color: #32383a !important;
    }

    .btn-secondary:first-child {
      svg {
        stroke: none !important;
        fill: transparent !important;
        path {
          fill: transparent !important;
        }
      }
    }

    button {
      height: 72px;
    }

    .button-body {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        > path {
          stroke: #000;
        }
        margin-right: 12px;
      }
    }

    .ach {
      > svg {
        stroke: black;
      }
      color: #32383a !important;
    }

    .ach-button {
      flex: 1;
    }

    .card-button {
      flex: 1;
    }
  }

  .link-button:hover {
    cursor: pointer;
  }

  .amount-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e8e9;
    border-radius: 4px;
    margin-top: 0;
    padding: 21px;
    margin-bottom: 24px;

    .amount {
      font-weight: bold;
      color: #242d34;
      font-size: 29px;
      line-height: 32px;
      margin-bottom: 7px;
    }

    .amount-box-date {
      color: #7d8c91;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 48px !important;
    border-radius: 4px;
    padding-left: 1px;
    margin-bottom: 24px;
  }

  .tab {
    height: 40px;
    width: 49%;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    font-weight: normal !important;

    font-size: 14px;
    line-height: 16px;
    outline: none;
  }

  .tab:focus {
    outline: 0 !important;
  }

  .selected {
    border: none;
    background-color: #ffffff;
    color: #242d34;
    box-shadow: 0px 2px 5px #cdd8e6;
  }

  .unselected {
    background-color: transparent;
    border: none;
    color: #7d8c91;
  }

  .manual-payment-fields {
    flex: 1;

    .select__control {
      border-radius: 3px !important;
    }

    .payment-type-label {
      margin-bottom: 10px;
    }

    .form-control {
      border-radius: 3px !important;
      border: 1px solid #ccd8e4;

      font-size: 15px !important;
      line-height: 18px !important;
      letter-spacing: 0.428571px !important;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  .manual-payment-fields:not(:first-child) {
    margin-left: 25px;
  }

  .manual-payment-fields:first-child {
    .formik-validation-error {
      margin-top: 14px;
    }
  }

  .manually-payment-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: -15px !important;
    margin-top: 10px !important;
  }

  .accepted-credit-cards {
    .label {
      color: #8a949d;
      font-size: 13px;
      line-height: 15px;

      font-weight: normal !important;
    }

    .credit-cards {
      margin-bottom: 11px;
    }
  }

  .manual-payment-form {
    display: flex;
  }

  .credit-card-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .credit-card-fields {
      flex: 1;
      .labels {
        color: #242d34;
        font-size: 13px;
        line-height: 15px;

        font-weight: normal !important;
      }

      .form-group {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }

      .form-control {
        border-radius: 3px !important;
        border: 1px solid #ccd8e4;
        height: 40px;

        font-size: 15px !important;
        line-height: 18px !important;
        letter-spacing: 0.428571px !important;
        margin-top: 0.5rem;
        margin-bottom: 1rem;

        &::placeholder {
          color: #b5bfc6 !important;
        }
      }
    }

    .credit-card-fields:not(:first-child) {
      margin-left: 25px;
    }
  }

  .manual-payment-info {
    display: flex;
    background-color: #eaeef2 !important;
    border-radius: 2px;
    padding: 1rem;
    margin-top: 24px;
    margin-bottom: 24px;
    color: #242d34;
  }

  .manual-payment-info-message {
    font-size: 16px;
    line-height: 24px;
    color: #242d34;
  }

  .confirm-payment-info {
    display: flex;
    background-color: #eaeef2 !important;
    border-radius: 2px;
    padding: 1rem;
    margin-top: 24px;
    color: #242d34;
  }

  .confirm-payment-info-message {
    font-size: 16px;
    line-height: 24px;
    color: #242d34;

    .amount {
      font-weight: bold !important;
    }
  }

  .manually-payment-modal-footer {
    margin: 0;
    padding: 11px 2rem;
  }

  .confirm-payment-modal-footer {
    margin: 0;
    padding: 11px 2rem;
  }

  .payment-start {
    border: 1px solid #ccd8e4;
    border-radius: 3px;
    padding: 24px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;

    .start-date {
      color: #01b5f5;
      margin-left: 1rem;

      .fts-icon {
        margin-right: 8px;
      }
    }

    .payment-info {
      display: flex;

      .info-message-recurring {
        font-size: 16px;
        line-height: 24px;
        color: #242d34;
      }
    }

    hr {
      margin-top: 23px;
      margin-bottom: 23px;
      background-color: #ccd8e4;
    }
  }

  .btn-primary {
    margin-top: 0 !important;
    border-radius: 4px;
  }

  .payment-overview-table {
    width: 100%;
    margin-top: 40px;
    // border: 1px solid #e3e7eb;
    box-sizing: border-box;
    border-radius: 2px !important;
    // margin-bottom: 1rem;
    overflow-x: auto;
    margin-bottom: 1.5rem;

    table {
      width: 100%;
      background: #ffffff;
      border-spacing: 15px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;

      th {
        height: 48px;
        color: #8a949d;

        font-size: 14px;
        line-height: 16px;
        text-align: center;
        border-bottom: 1px solid #e3e7eb;
        font-weight: normal !important;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid #e3e7eb;
      }

      td {
        height: 112px;
        align-items: center;
        text-align: center;
        vertical-align: top;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-right: 50px;
      }

      .vx-checkbox--check {
        background-color: #647074 !important;
      }
      .vx-checkbox-con input:checked ~ .vx-checkbox {
        border-color: #647074;
      }

      .payment-listings-header {
        text-align: left;
      }

      .items-paid {
        min-width: 300px;
      }

      .payment-listings {
        width: 300px;
        text-align: left;
        div {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-start;

          .company-name {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #242d34;
          }

          .city-state {
            margin-top: 8px;
            font-size: 12px;
            line-height: 14px;
            color: #61686a;
          }
        }
      }

      .payment-number-header {
        text-align: left;
        padding-left: 7px;
      }

      .payment-due-header {
        text-align: left;
      }

      .payment-due {
        text-align: left;
        font-size: 12px;
        line-height: 14px;
        color: #242d34;
      }

      .payment-type {
        text-align: left;

        div {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-start;

          .type {
            font-size: 14px;
            line-height: 14px;
            color: #8a949d;
          }

          .price {
            margin-top: 5px;
            font-weight: 500;
            font-size: 15px;
            line-height: 19px;
            color: #242d34;
          }
        }
      }

      .payment-type-header {
        text-align: left;
      }

      .payment-price-header {
        text-align: right;
        padding-right: 24px;
      }

      .payment-total-box {
        font-weight: bold;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 24px;
        font-size: 16px;
        line-height: 20px;
        .total {
          padding-left: 5px;
          color: #32383a;
        }
      }

      .invoice-number-cell {
        display: flex;
        flex-direction: row;

        color: #61686a;
        font-size: 12px;
        line-height: 14px;
      }

      .invoice-amount-cell {
        color: #242d34;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        padding-right: 24px;
      }
    }
  }
}

.btn-secondary {
  color: #8b97a3 !important;
  background-color: transparent !important;
  outline: none;
  border: none;

  &:hover {
    color: #8b97a3 !important;
    outline: none;
    border: none;
  }
  &:focus {
    color: #8b97a3 !important;
    background-color: transparent !important;
    outline: none;
    border: none;
  }
}

.payment-total-box-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 16px;
  line-height: 20px;

  .total {
    font-weight: 700;

    color: #32383a;
  }
}

@media (max-width: 1270px) {
  .fts-payment-modal {
    h3 {
      font-size: 20px;
      line-height: 24px;
    }

    .payment-modal-body {
      .invoice-number-cell {
        min-width: 110px;
      }

      .download-receipt-btn {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
        font-size: 14px !important;
        line-height: 24px !important;
        svg {
          margin-right: 11px !important;
        }
        .fts-icon {
          margin: 0;
        }
      }
      padding: 24px !important;
      .btn {
        padding: 12px !important;
      }
      .button-body {
        font-size: 12px;
        line-height: 16px;
        flex-direction: column;
        align-items: center;
        text-align: center;

        svg {
          margin-right: 0;
          margin-bottom: 8px;
        }

        span {
          margin-left: 0 !important;
        }
      }

      .amount-box {
        margin-top: -2rem;
      }

      .payment-start {
        padding: 16px;

        .start-date {
          font-size: 14px;
          svg {
            margin-left: -0.5rem;
          }
        }
        hr {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }

      .credit-card-form {
        margin-top: 16px;
        margin-bottom: 18px;

        span {
          flex-direction: column;
        }

        .credit-card-fields {
          margin: 0;

          .labels {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: #4b5457;
          }
        }
      }
    }
  }
}

@include target-breakpoint-and-smaller("sm") {
  .payment-modal-main-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    h3 {
      padding-bottom: 16px;
    }
  }

  .fts-close-btn {
    position: absolute;
    right: 16px;
  }

  .fts-payment-modal .card-remove-icon {
    margin-right: 0px !important;
    margin-bottom: 24px;
  }

  .modal-footer {
    .btn {
      width: 100%;
    }
  }

  .payment-method-title-text-heading {
    font-size: 18px !important;
  }
}
