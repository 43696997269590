.fts-report-preview-page {
  .fts-listing-tab-wrapper {
    padding: 120px 0px;
  }

  .reports-title-row {
    width: 100%;
    text-align: center !important;
    color: #32383a;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #2c2f36;
  }

  .reports-title-row {
    display: none;
  }

  &-chart-title {
    padding-top: 20px;
    padding-left: 30px;

    h2 {
      display: inline !important;
      font-size: 24px !important;
      line-height: 28px !important;
    }
  }

  .fts-listing-tab-wrapper {
    padding: 0px;
    margin: 30px 0px;
  }

  .revenue-calculator .heading {
    padding-bottom: 0px;
  }

  .revenue-calculator-wrapper {
    background-color: #ffffff;
    width: 100%;
  }

  .FTS-chart {
    height: 300px;
  }

  .chartSubheading {
    margin-bottom: 32px;
  }

  .calculatorWrapper {
    background-color: #fff !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.141472);
    border-radius: 4px;
  }

  .revenueChartContent {
    padding: 32px;
  }
}

@media (max-width: 600px) {
  .fts-report-preview-page h2 {
    font-size: 20px;
    line-height: 24px;
  }
}
