//@import "../node_modules/prismjs/themes/prism-tomorrow";
@import "./assets/scss/app.scss";

//@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
.paypal-buttons {
  display: none;
}

nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.fts-link {
  color: $primary;
  font-size: 14px;
  cursor: pointer;
}

.Toastify__toast-container {
  width: auto !important;
  min-width: 320px !important;
}

.Toastify__toast--info {
  opacity: 1 !important;
}

.Toastify__toast-body {
  color: #fff;
}

.Toastify__close-button--info {
  color: black !important;
  font-size: 16px !important;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.notification-header-unread {
  .unread {
    background-color: #01b5f5;
    border-radius: 4px;
  }
  svg {
    g {
      stroke: white;
      fill: white;
      stroke-width: 0.5px;
    }
  }
}
.react-contextmenu-item span {
  margin-left: 10px;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.react-contextmenu-item .copy {
  color: skyblue;
}

.row-update-red {
  background-color: #fff5f7;
}

.row-active {
  background-color: #f6fdff;
  border-left: 5px solid #00b5f4 !important;
  border-radius: 5px;
}

.row-active-green {
  background-color: #fff;
  border-left: 5px solid #57c990 !important;
  border-radius: 5px;
}

.row-active-listings-green {
  background-color: #f3fbf7;
  border-left: 5px solid #57c990 !important;
  border-radius: 5px;
}

.row-inactive-red {
  background-color: #fff;
  border-left: 5px solid #f36565 !important;
  border-radius: 5px;
}

.row-deleted {
  background-color: #f6fdff !important;
  opacity: 0.5 !important;
  border-left: 5px solid #f6fdff !important;
  border-radius: 5px !important;
}

.row-disabled {
  background-color: #f8f8f8;
  border-left: 5px solid #edf2f3 !important;
  border-radius: 5px;
}

.row-gray-out {
  color: gray !important;
}

.row-warning {
  background-color: #fff5da;
  color: gray !important;
  border-left: 5px solid #f5d276 !important;
  border-radius: 5px;
}

.row-suspended {
  background-color: #fff5f5;
  border-left: 5px solid #f36565 !important;
  border-radius: 5px;
}

.row-note-border-green {
  border-left: 3px solid #98ca86 !important;
  border-radius: 3px;
}

.row-note-border-red {
  border-left: 3px solid #f36565 !important;
  border-radius: 3px;
}

.row-broder-red {
  border-left: 3px solid #ce1b3b !important;
  border-radius: 3px;
}

.row-note-border-gray {
  border-left: 3px solid #7d8c91 !important;
  border-radius: 3px;
}

.row-active {
  background-color: #fff;
  border-left: 5px solid #57c990 !important;
  border-radius: 5px;
}

.row-pending {
  background-color: #fff;
  border-left: 5px solid #ffc734 !important;
  border-radius: 5px;
}

.row-call-logs {
  border-left: 3px solid #00b5f4 !important;
  border-radius: 3px;
}

.row-border-green {
  border-left: 3px solid #57c990 !important;
  border-radius: 3px;
}

.react-contextmenu-wrapper {
  min-width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.ag-react-container {
  min-width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.spinner-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
}

//
//.column-item {
//  top: 0px;
//  opacity: 1;
//}
//
//.transform-column-item {
//  transition: all 5s;
//  top: 50vh;
//  opacity: 0;
//  position: absolute;
//}

.transform-column-item-down {
  //.fts-todo-wrapper {
  //  .fts-todo-small {
  //    .fts-todo-small-body {
  //    }
  //  }
  //}

  animation: fadeOutFromNone 2s linear;
  top: var(--mouse-y);
  z-index: 200;
}

@keyframes fadeOutFromNone {
  0% {
    transform: scale(1);
    opacity: 1;
    top: var(--mouse-y);
  }
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: translate(0px, 0px) scale(0.7);
    //transform: scale(0.7);
    //position: absolute;
    //opacity: 1;
    //top: var(--mouse-y);
  }
  100% {
    transform: translate(0px, 50vh) scale(0.7);
    position: absolute;
    opacity: 0.7;
  }
}

.transform-column-item-up {
  //.fts-todo-wrapper {
  //  .fts-todo-small {
  //    .fts-todo-small-body {
  //    }
  //  }
  //}

  animation: fadeOutToUp 2s linear;

  z-index: 200;
}

@keyframes fadeOutToUp {
  0% {
    transform: scale(1);
    opacity: 1;
    top: var(--mouse-y);
  }
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: translate(0px, 0px) scale(0.7);
    //transform: scale(0.7);
    //position: absolute;
    //opacity: 1;
    //top: var(--mouse-y);
  }
  100% {
    transform: translate(0px, -50vh) scale(0.7);
    position: absolute;
    opacity: 0.7;
  }
}

.task-management__container {
  height: 80vh !important;
}

.bg-popover {
  background: #4b5457;
  border-radius: 4px;
}

.bg-void-popover {
  background: #4b5457;
  border-radius: 4px;
  span {
    color: #fff !important;
  }
}

.ag-overlay-wrapper {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.01) !important;
}

.tooltip {
  z-index: 2000 !important;
}

.stroke-none {
  stroke: none !important;
}


#listing_item_descriptions * {
  all: revert !important;
  margin: 0 !important;
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
  word-wrap: break-word !important;
}
.bg-devider {
  background-color: #EAEAF1;
  padding: 0.5rem;
}
#etc_info{
  margin-right: 0.5rem;
  >path {
  stroke: #0b0b0b;
}
}

input[type="password"] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;

  &::-ms-reveal,
  &::-ms-clear {
    display: none !important;
  }
}

input[type="password"] {
  -webkit-text-security: disc !important;
}

.gm-style-iw-ch {
  padding-top: 0;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
