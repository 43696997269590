.see-report-btn {
  display: flex;
  align-items: center;
  color: #ce1b3b;

  svg {
    stroke: #ce1b3b !important;
    stroke-width: 0.5px !important;
  }
}

.see-report-btn-disabled {
  cursor: default !important;
  color: #8c919f !important;

  svg {
    stroke: #8c919f !important;
    stroke-width: 0.5px !important;
    path {
      fill: #8c919f !important;
    }
  }
}
