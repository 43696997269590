.chart {
  height: 285px;
  width: 100%;
  padding: 0px 30px;
  padding-right: 0;
}

.chartWrapper::-webkit-scrollbar {
  display: none;
}

.chartWrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 24px;
}

.FTS-revenue-chart-legend {
  display: flex;
  align-items: center;
  justify-content: center;
}
