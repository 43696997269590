#pdf {
  // min-width: 612px;
  // max-width: 612px;

  //min-height: 791px;
  //max-height: 791px;

  overflow-x: auto;
  .fts-listing-item,
  .fts-footer,
  .client-card-section,
  .report-page-btn,
  .more-listings-btn,
  .fts-renew-banner,
  .FTS-info-card-label-wrapper > svg,
  .listing-report-preview-back-btn {
    display: none;
  }

  .account-details-banner {
    display: flex;
  }
}
.FTS-temporary-link-report {
  .client-card-section {
    margin-bottom: 0px !important;
  }
}

.account-report,
.listing-report {
  .fts-title-row .col h4 {
    font-size: 16px;
    line-height: 24px;
    color: #2c2c2c;
    font-style: unset;
    font-weight: bold !important;
    letter-spacing: 1.9px !important;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 21px;
  }

  .header-report-controls {
    display: flex;
    padding: 0px;
    margin-top: 1rem;
    margin-right: 1rem;
    min-height: 48px;

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #f1f5fa;
      height: 48px;
      margin-right: 1rem;
      border-radius: 4px;
      margin-right: 1rem;
    }

    .tab {
      height: 40px;
      min-width: 46px;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      font-weight: normal !important;

      font-size: 14px;
      line-height: 16px;
      outline: none;
    }

    .tab:focus {
      outline: 0 !important;
    }

    .tab:first-child {
      margin-left: 4px;
      margin-right: 2px;
    }

    .tab:last-child {
      margin-left: 2px;
      margin-right: 4px;
    }

    .selected {
      border: none;
      background-color: #ffffff;
      color: #242d34;
      box-shadow: 0px 2px 5px #cdd8e6;
    }

    .unselected {
      background-color: transparent;
      border: none;
      color: #7d8c91;
    }

    .range {
      width: 270px;
      height: 48px;

      .clear-calendar-button {
        transition: 3000ms color ease;

        svg {
          stroke: #ccc;
        }
      }

      .clear-calendar-button:hover {
        svg {
          stroke: #999;
        }
      }

      .form-control-position {
        z-index: 3;
      }

      .fts-date-picker {
        height: 48px !important;
        background-color: transparent !important;
        border: 1px solid #e3e7eb !important;
        border-radius: 4px;
        margin-left: 0.5rem;
      }
    }

    .flatpickr-input {
      background-color: transparent !important;
    }
  }

  .generate-report-button {
    svg {
      margin-right: 0.5rem !important;
    }
  }

  .download-report-button {
    color: #32383a !important;
    border: 1px solid #f2f6f9 !important;
  }

  .send-report-btn {
    background-color: transparent !important;
    color: #32383a !important;
    border: 1px solid #cbd1d3 !important;

    svg {
      margin-left: 0.5rem !important;
    }
  }

  .nav-item:first-child {
    padding-left: 1.6rem;
  }

  .account-report-content {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;

    .tab-content {
      padding: 0px 2.5rem;
      padding-top: 1rem;
    }
  }

  .fts-report-actions {
    margin: 0 -2.5rem -1rem -2.5rem;
    padding: 1.35rem 2.5rem 0 0;
    border-top: 1px solid #e5e8e9;
  }

  .account-report-title-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    //margin-top: 64px;
  }

  .fts-listing-item {
    min-width: unset;
    border: 1px solid #00b5f4 !important;
    border-radius: 4px !important;

    .action-bar {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      margin-left: -1.7rem;
      margin-right: -1.7rem;
      padding-left: 1.65rem;
      padding-right: 1.65rem;
    }

    .action-bar-free {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: none;
    }
  }

  .report-page-btn {
    height: 56px;
    color: #32383a !important;
    background-color: #e5e8e9 !important;
    cursor: pointer;
  }

  .report-page-btn:focus {
    color: #32383a !important;
    background-color: #e5e8e9 !important;
  }

  .report-page-btn:hover {
    color: #32383a !important;
    background-color: #e5e8e9 !important;
  }

  .more-listings-btn {
    width: 100%;
    height: 56px;
    color: #32383a !important;
    background-color: #f2f6f9 !important;

    margin-top: 32px;
  }

  //.more-listings-btn:hover {
  //  color: #32383a !important;
  //  background-color: #e6edf2 !important;
  //}

  .more-listings-btn:focus {
    color: #32383a !important;
    background-color: #e5e8e9 !important;
  }

  .listing-report-preview-back-btn {
    margin-bottom: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: #2c2f36;

    svg {
      margin-right: 4px;
    }
  }
}

.report-temporary-link-header-group {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../../../assets/img/bg/ReportHeading.jpg");

  .fts-vendor-header {
    background-color: transparent !important;
  }

  .header-wrapper {
    background: transparent !important;
  }
}

.redUnderline {
  position: relative !important;
  width: 48px;
  height: 4px;
  top: 0px !important;
  margin: 9px 0px;
  background: #ce1b3b;
}

#pdf-wrapper {
  position: absolute;
  width: 100%;
  opacity: 0;
  z-index: -1;
}

#pdf {
  .container {
    min-height: 1000px;
    max-height: 1000px;
    min-width: 794px;
    max-width: 794px;
  }

  .FTS-chart {
    max-height: 400px !important;
    min-height: 350px !important;
    min-width: unset !important;
    padding-top: 20px;
  }

  .exposure-chart-wrapper {
    margin: 0px;
  }

  .FTS-info-card-wrapper {
    width: unset;
    min-width: unset;
  }

  .chartSubheading p {
    //font-size: 15px;
    //line-height: 20px;
    //margin-top: 31px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .redUnderline {
    margin: 8px 0px !important;
  }

  #FTS-user-engagement {
    margin-top: 168px;
  }

  .account-report {
    > h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .chartSubheadingFlex {
    > h3 {
      margin-top: 10px;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .fts-listing-tab-wrapper {
    padding: 0px 16px !important;
  }

  .account-report-title-row {
    margin: 0px !important;
  }

  .account-report p {
    margin: 0px 0px 10px 0px;
  }

  .account-report .exposure-chart-wrapper {
    margin-top: 0px;
  }

  .account-report-title-row h2 {
    font-size: 14px;
    line-height: 16px;
  }

  .chartSubheadingFlex > h3 {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .FTS-info-card-wrapper {
    max-height: 55px !important;
  }

  .account-report p {
    font-size: 12px;
    line-height: 14px;
  }

  .fts-report-preview-page .chartSubheading {
    margin: 0px !important;
    padding: 0px !important;
  }

  .new-wrapper {
    padding: 0px !important;
    border: 1px solid #e5e8e9;
  }

  .FTS-info-card-label {
    font-size: 12px !important;
  }

  .FTS-info-card-wrapper {
    padding: 8px !important;
  }

  .FTS-info-card-amount {
    font-size: 18px !important;
  }
}

.account-details-banner {
  position: relative;
  background: #f2f3f4;
  border-radius: 3px;
  padding: 10px;
  display: none;
  margin-bottom: 10px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px !important;
      line-height: 16px !important;
      /* identical to box height, or 112% */

      /* Color/Gray/800 */

      color: #32383a;
    }

    > p {
      margin: 0px;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 14px !important;
      /* identical to box height, or 117% */

      /* Color/Gray/800 */

      color: #32383a;
    }
  }

  .arrow {
    //z-index: 100;
    background: #f2f3f4;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50%;
    top: calc(100% - 12px);

    //background: #000;
    border-radius: 3px;
    transform: rotate(-45deg);
  }

  > .logo {
    border-right: 1px #cbd1d3 solid;
    display: flex;
    align-items: center;
    padding-right: 32px;
    margin-right: 32px;
  }
}

.send-report-wrapper {
  max-width: 300px;
  position: absolute;
  z-index: 100;
  background: white;
  padding: 18px !important;
  right: 20px;
  bottom: 775px;
  box-shadow: 0px 3px 9px darkgray;
  border-radius: 4px;

  .nav {
    .tab {
      height: 40px;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      flex: 1;
      font-weight: normal !important;

      font-size: 14px;
      line-height: 16px;
      margin-left: 4px;
      margin-right: 4px;
      outline: none;
    }

    margin-bottom: 16px;
    margin-right: 0px !important;
  }

  #payment-link-form {
    > button {
      margin-top: 16px;
    }
  }

  .send-report-text {
    margin-top: 16px;
    margin-bottom: 16px;

    svg {
      margin-right: 10px;
    }
  }
}

.listing-send-report-wrapper {
  bottom: 855px !important;
}

@media (max-width: 600px) {
  .FTS-temporary-link-report {
    .account-report {
      margin-top: 24px !important;
    }

    .account-report-title-row {
      h2 {
        font-size: 20px !important;
        line-height: 24px !important;
      }

      p {
        margin-top: 16px;
      }
    }

    .fts-listing-item {
      border-radius: 0 !important;
      margin-left: -1rem;
      margin-right: -1rem;
      border-left: none !important;
      border-right: none !important;
    }
  }
  .report-temporary-link-header-group {
    background-image: url("../../../assets/img/bg/ReportHeadingMobile.jpg");

    .container {
      padding: 40px 24px;
      flex-direction: column;

      .donwnload-pdf-report-button-wrapper {
        width: 100%;

        .btn {
          margin-top: 24px;
          width: 100%;
          margin-left: 0px !important;
        }
      }

      .headerContentLeft {
        flex-direction: column;

        .listing {
          border-top: 1px solid rgba(250, 250, 252, 0.5);
          border-left: none;
          padding-left: 0px;
          margin-top: 16px;
          padding-top: 16px;
        }
      }
    }
  }

  .account-report .exposure-chart-wrapper {
    margin: -1rem;
  }
}

@media (max-width: 767px) {
  .account-report-title-row {
    flex-direction: column !important;
    margin-bottom: 40px !important;
    margin-top: 0px !important;

    p {
      margin-bottom: 0px;
    }

    .report-page-btn {
      margin-top: 40px !important;
      width: 100%;
    }
  }
}
