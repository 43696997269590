.promo-code-input-field {
  position: relative;
  width: 100%;

  .form-control {
    padding-right: 65px;
  }

  .apply-button-temporary {

    font-size: 14px;
    line-height: 24px;
    position: absolute;
    right: 16px;
    bottom: 13px;
    padding: 0 !important;
    cursor: default;
  }

  .apply-button-temporary-disabled {
    color: gray !important;
  }

  .apply-button-temporary-disabled:hover {
    cursor: default;
    color: gray !important;
  }

  .apply-button-temporary-active {
    cursor: pointer;
    color: #00b5f4 !important;
  }

  .apply-button-temporary-active:hover {
    cursor: pointer;
    color: #00b5f4 !important;
  }
}

.promo-code-input-field-applied {
  display: flex;
  flex-direction: column;
  background: #e9fef2;
  border-radius: 4px;
  padding: 16px;

  .promo-code-applied {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #32383a;
    margin-bottom: 16px;
  }

  .promo-code-remove {
    text-decoration-line: underline;
    color: #32383a;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }
}
