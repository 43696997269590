@import "../../scss/core/responsive/responsive";

$fts-modal-border-radius: 0.6rem;
$fts-modal-padding: 1.5rem;

.fts-modal {
  &__free-listing-preview {
    min-width: max-content;
    // Modal Header

    &__title-description {
      display: flex;
      align-items: self-end;
      justify-content: center;
      color: #fff;
      width: max-content;

    }

    .modal-header {
      margin: (-$fts-modal-padding) (-$fts-modal-padding) 0
        (-$fts-modal-padding);

      background-color: #ce1b3b !important;
      padding: 20px 20px 0 2.5rem !important;

      border: none;

      border-radius: $fts-modal-border-radius;
      border-bottom-left-radius: unset !important;
      border-bottom-right-radius: unset !important;

      div {
        border-left: none;

        div {
          h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: white;
            width: max-content;
          }
        }
      }

      > div:last-of-type {
        div {
          border-radius: 50%;
        }
      }

      // close button
      .close {
        display: none;
      }

      .fts-close-btn {
        border-radius: 50%;
      }

      .fts-title-row {
        .col:first-of-type {
          border-left-color: transparent;
        }
      }

      .modal-title {
        width: 100%;
      }

      &[class*="bg-"] {
        color: white;
        border-bottom-left-radius: $fts-modal-border-radius;
        border-bottom-right-radius: $fts-modal-border-radius;

        .modal-title {
          color: white;
          width: 100% !important;
        }
      }

      .modal-content {
        border-radius: 4px;
      }
    }

    .modal-body {
      padding: 24px !important;

      .free-listing {
        &__content-title {
          padding-top: 24px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #2c2f36;
        }

        &__content-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #2c2f36;
        }
      }

      .fts-checkbox-con {
        padding-top: 40px;
        font-size: 12px;

        > span {
          background-color: #647074;

          svg {
            path {
              fill: white;
            }
          }
        }

        > div {
          font-size: 14px !important;
          line-height: 16px !important;
        }
      }

      ul {
        padding: 0 24px;
        margin: unset;

        li {

          font-size: 14px;
          line-height: 18px;
          color: #2c2f36;
        }
      }
    }

    &__checkbox {
      div {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        border: none;
      }
    }

    &__button {
      padding: 16px 24px !important;
      border-radius: 4px;
      margin-right: 12px;
    }

    .modal-footer {
      border-top: 1px solid #e6e6f5;
    }
  }
}

@include target-breakpoint-and-smaller("sm") {
  .fts-modal {
    .modal-body {
      padding: 0 !important;
    }
    &__free-listing-preview {
      .title-header-wrapper {
        min-width: 85%;
      }
      .close-button-wrapper {
        top: -6px;
      }
      &__title-header {
        flex-direction: column;
        padding-bottom: 8px;
        width: 80%;
      }

      &__title-description {
        width: unset;
        line-height: 16px;
        font-size: 16px;
      }
      .modal-content {
        max-width: 90vw;
      }
      .fts-listing-item {
        padding: 0;
        box-shadow: none;
        max-width: 100%;
        min-width: 100%;

        &-content {
          .fts-listing-item-info {
            padding: 0;
          }

          .fts-listing-item-info .title-row {
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
}
