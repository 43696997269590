.FTS-info-card-wrapper {
  display: flex;
  position: relative;
  border-radius: 4px;
  flex: 1;
  height: 84px;

  padding: 18px;

  svg {
    outline: none;
    path {
      fill: #647074;
    }
  }
}

.FTS-info-card-color-indicator {
  width: 3px;
  height: 100%;
  border-radius: 4px;
  margin-right: 16px;
}

.FTS-info-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.FTS-info-card-wrapper:not(:last-child) {
  margin-right: 1rem;
}

.FTS-chart-heading-red-box {
  position: absolute;
  width: 5px;
  left: -36px;
  height: 14px;
  background-color: red;
}
.FTS-info-card-label-wrapper {
  display: flex;
  justify-content: space-between;
}

.FTS-info-card-label {
  font-size: 14px;
  line-height: 16px;
  color: #97a3a7;
  margin-bottom: 4px;
}

.FTS-info-card-amount {
  font-size: 24px;
  line-height: 24px;
  color: black;
}

@media (max-width: 600px) {
  .FTS-info-card-wrapper {
    width: 145px;
    margin-bottom: 10px;
  }
}
