.fts-sticky-payment-box {
  position: sticky;
  top: 32px;
  z-index: 1000;
  margin-top: 42px;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 3px 9px #ededf3;
  width: 316px;
  height: max-content;
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-backdrop {
    transition: opacity 0.15s linear;
    opacity: 0.5;
    background-color: #22292f;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &-body {
    padding: 40px 40px 32px 40px;

    &-title {
      margin-left: -40px;
      padding-left: 34px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      border-left: 6px solid #ce1b3b;
      color: #32383a;
    }

    .promo-code-error-message {
      color: #f20933;
      font-size: 12px;
      margin-top: 5px;
      margin-left: 2px;
    }

    &-counters {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #32383a;
      margin-top: 8px;
    }

    &-calculate {
      &-input {
        margin-bottom: 24px;
        margin-top: 24px;
      }

      &-row {
        display: flex;
        justify-content: space-between !important;
        width: 100%;
        span {
          display: block;
          font-size: 16px !important;
          line-height: 20px !important;
        }
      }
    }

    .invoice-overview-footer {
      padding: 0;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      color: #32383a;
    }

    &-counters {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #32383a;
      margin-top: 8px;
    }
  }

  &-footer {
    padding: 16px 40px;
    border-top: 1px solid #e5e8e9;

    .btn-primary {
      margin: 0 !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

@media (max-width: 1270px) {
  .fts-sticky-payment-box {
    width: 100vw;
    margin: 0;
    position: fixed;
    bottom: 0;
    top: unset;
  }

  .fts-sticky-payment-box-body-title {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .fts-sticky-payment-box-body-title {
    border-left: none !important;
    margin-left: -2.3rem !important;
  }

  .fts-sticky-payment-box-body-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 6px solid #ce1b3b;
    margin-left: -40px;
    padding-left: 34px;

    span {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (min-width: 1271px) {
  .fts-sticky-payment-box-chevron {
    display: none !important;
  }
  .fts-sticky-payment-box-body-counters {
    margin-bottom: 24px;
  }
}
