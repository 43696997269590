@import "../core/responsive/responsive.scss";

@include target-breakpoint-and-smaller(sm) {
  .fts-update-listing {
    .fts-header-update-listing-message {
      margin-bottom: 0px !important;
      padding: 16px 24px !important;
      color: #32383a;
    }
    .col {
      width: 100%;
    }
    h2 {

      padding: 24px;
      margin-bottom: 0px !important;
      width: 100%;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .col-10 {
      padding: 0;
      max-width: unset;
    }
    .offset-1 {
      margin-left: 0px !important;
    }
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .card {
      width: 100vw !important;
    }
    .col {
      width: 100vw !important;
    }
  }

  .listing-actions-mobile {
    display: none !important;
  }
  .fts-update-listing-footer {
    border-top: 1px solid #e5e8e9;
    background-color: #fff !important;
  }
}
