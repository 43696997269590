@import "../../core/responsive/_responsive.scss";

.fts-private-location-badge {
  background-color: #ccf0fd;
  border-radius: 4px;
  padding: 0px 8px;

  &-text {
    color: #32383a;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

@include target-breakpoint-and-smaller("sm") {
  .fts-private-location-badge {
    max-width: fit-content;
  }
}
