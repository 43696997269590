.revenue-calculator {
  .heading {
    padding-bottom: 24px;
  }

  .calculatorWrapper {
    padding-bottom: 32px;
    .headings {
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0px !important;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
      }
    }
  }

  .calculator {
    padding: 40px 32px;
    position: relative;
    display: flex;
    align-items: center;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: rgb(44, 47, 54);
    background-image: url("../../../assets//img/svg/revenue-calculator-background.svg");
  }

  .arrow {
    position: absolute;
    bottom: -12px;
    left: calc(50% - 12px);
    transform: rotate(45deg);
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: rgb(44, 47, 54);
  }

  .headings {
    max-height: 56px;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;
    color: white;
  }

  .headings > h3 {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .headings > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }

  .form {
    width: 43%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .form > button {
    height: 56px;
    color: white !important;
    margin-left: 16px;
    background-color: #00b5f4 !important;
  }

  .form > button:hover {
    color: white !important;
    background-color: #00b5f4 !important;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  }

  .form > button:focus {
    color: white !important;
    background-color: #00b5f4 !important;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  }

  .dollar-wrapper {
    position: relative;
    .dollar {
      position: absolute;
      color: #32383a;
      left: 16px;
      top: 19px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .revenueInputWrapper {
    color: #32383a;
    width: 305px;
    background-color: #fbfcfd;
    border-radius: 4px;
    height: 56px;
    padding: 1rem 18px;
    border: unset;
    display: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .revenueInputWrapper:focus {
    outline: unset;
  }

  .estimatedRevenue {
    padding: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00b5f4 !important;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 2rem;

    svg {
      cursor: pointer;
      path {
        stroke: #fff !important;
        stroke-width: 0.3px !important;
        fill: #fff !important;
      }
    }
  }

  .revenueInputWrapper::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .revenueInputWrapper::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .revenueInputWrapper[type="number"] {
    -moz-appearance: textfield;
  }

  .left {
    flex: 1;
    padding-left: 2px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .estimatedRevenue > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    margin-bottom: 0;

    color: white;
    > span {
      margin-left: 8px;
    }
  }

  .industryAverage {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .industryAverage > h2 {
    text-align: center;
  }

  .industryAverageHeader {
    line-height: 2rem;
    display: flex;
    margin-top: 32px;
    margin-bottom: 34px;
  }

  .industryAverageHeader > h2 {
    margin-bottom: 0px;
  }

  .infoCards {
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 30%;
  }

  .revenueChartContent {
    display: flex;
    padding-top: 32px;
    .FTS-info-card-wrapper {
      flex: unset;
    }
  }
}

@media (max-width: 600px) {
  .revenueChartContent {
    display: flex;
    flex-direction: column;
  }

  .revenue-calculator .infoCards {
    width: 100%;
  }

  .FTS-info-card-wrapper {
    min-width: 140px;
  }

  .FTS-info-card-wrapper:not(:last-child) {
    margin: 0;
  }

  .revenue-calculator {
    .headings {
      width: 100%;
      max-height: unset;
    }

    .estimatedRevenue {
      padding-left: 0px;

      h2 {
        font-size: 16px;
      }
    }

    .revenueChartContent {
      padding-bottom: 0px;
    }

    .calculator {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-radius: 0px;

      .form {
        width: 100%;
        display: flex;
        flex-direction: column;

        .btn {
          width: 100%;
          margin: 16px 10px;
        }

        .dollar-wrapper {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }
    }

    .calculatorWrapper {
      margin: -1rem;
    }

    .calculatorWrapper .headings {
      p {
        white-space: initial;
      }

      h3 {
        font-size: 16px;
      }
    }

    .revenue-calculator-wrapper {
      margin: -1rem;
      width: 100vw;

      .chartWrapper {
        overflow: auto;
      }
      .chart {
        padding: 0;
        width: 800px;
      }
    }
  }

  .FTS-revenue-chart-legend {
    margin: 0px -32px;
    padding: 18px 32px 16px 32px;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-top: 1px solid #e5e8e9;
  }

  .FTS-revenue-chart-legend > div {
    padding-bottom: 16px;
  }

  .revenue-calculator-wrapper {
    padding-bottom: 0px;
  }
}
