@import "../../core/responsive/responsive.scss";

.fts-auth {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 #ededf3;
  margin: 32px auto;
  max-width: 578px;
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  font-family: "Kumbh Sans", serif;
  color: #32383a;
  gap: 40px;

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 8px;
    color: #32383A;
  }

  h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #32383A;
    margin-bottom: 0;
  }

  .back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #32383A;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;
    cursor: pointer;
    width: min-content;
    margin-bottom: 8px;
  }

  .form-group {
    label {
      color: #647074;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.2px;
      margin-bottom: 6px;
    }
    .form-control {
      border-radius: 4px;
      background-color: transparent;
      border: 1px solid #E5E8E9;
      color:  #32383A;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.2px;
    }

    .is-invalid {
      border-color: #F36565 !important;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15) !important;
      background-image: none !important;
    }
  }

  .or-devider {
    display: flex;

    align-items: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Kumbh Sans", serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;
    color: #647074;

    &-line {
      width: 100%;
      hr {
        border-top: 1px solid #E5E8E9;
      }
    }

    &-line:first-child {
      margin-right: 16px;
    }

    &-line:last-child {
      margin-left: 16px;
    }
  }

  .eye-input-field {
    position: relative;
    .eye-button {
      position: absolute;
      right: -7px;
      top: 0;
      background-color: transparent !important;

      svg {
        margin-bottom: 4px !important;
      }
    }
    .eye-button:hover {
      background-color: transparent !important;
    }
    .eye-button:active  {
      background-color: transparent !important;
    }

    .eye-button:focus {
      background-color: transparent !important;
    }
  }

  .btn-primary {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;
    width: 100%;
    height: 48px !important;
  }

  .btn-secondary {
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;
    height: 48px !important;
  }

  .select__control {
    background-color: transparent !important;
    border-radius: 4px;
  }
}

.account-selection-wrapper {
  margin-bottom: 32px;
  .fts-sticky-footer {
    .btn-primary {
      color: #FFF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.2px;
    }

    .btn-secondary {
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.2px;
      height: 48px !important;
    }
  }
  .fts-fixed-footer {
    .btn-primary {
      color: #FFF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.2px;
    }

    .btn-secondary {
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.2px;
      height: 48px !important;
    }
  }
}

.fts-complete-profile {
  margin-bottom: 32px;
  h4 {
    margin-bottom: 0;
  }
}

@include target-breakpoint-and-smaller(sm) {
  .fts-auth {
    padding: 24px;

    h2 {
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height:  38.4px;
    }

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }

  .fts-complete-profile {
    margin-top: 0 !important;
  }

  .account-selection-wrapper {
    .fts-sticky-footer {
      background-color: #fff;
      width: 100vw !important;
      position: sticky !important;
      bottom: 0;
      box-shadow: 0px -3px 9px 0px #EDEDF3;
      display: flex;
      padding: 16px 24px;
      align-items: center;
      gap: 16px;

      .btn {
        height: 48px !important;
      }
    }
    .fts-fixed-footer {
      background-color: #fff;
      width: 100vw !important;
      box-shadow: 0px -3px 9px 0px #EDEDF3;
      display: flex;
      padding: 16px 24px;
      align-items: center;
      gap: 16px;

      .btn {
        height: 48px !important;
        white-space: nowrap;
        width: 100%;
      }

      position: fixed !important;
      bottom: 0;
      left: 0;

    }
  }
}

@media (max-width: 386px) {
  .fts-auth {
    .fleet-carrier-trucks-and-trailers {
      .fixed-height {
        label {
          height: 40px !important;
        }
      }
    }
  }
}

@media (max-width: 361px) {
  .fts-auth {
    .other-profile-trucks-and-trailers {
      .fixed-height {
        label {
          height: 40px !important;
        }
      }
    }
  }
}


