@import "../core/variables/variables";
@import "../core/responsive/responsive";

.account-no-permission {
  position: absolute;
  margin: -40px -31px;
  min-width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  //align-items: center;
  > h1 {
    margin-top: 30px;
    color: white;
  }
}

.account-section {
  // border: 1px solid $gray-200;
  margin-top: 1rem;
  box-sizing: border-box;

  &.no-border {
    border: none;
    padding: 0;
    margin-top: 1.5rem;
  }

  &.first {
    margin-top: 0;

    .fts-reverse-switch-group {
      margin-top: -0.4rem;

      .fts-reverse-switch {
        margin-bottom: 0.25rem;

        &#corporate-switch {
          margin-bottom: 0;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
          transform: translateX(1.45rem);
        }
      }
    }

    .switch-label {
      padding-right: 0.5rem;
    }
  }
}

.business-section-wrapper {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    // top: -25px;
    left: -2.5rem;
    right: -2.5rem;
    bottom: 0;
    background-color: #f8f8f8;
  }
}

.fts-account-nav-wrapper {
  overflow: auto;
  max-width: 100%;
}

.fts-nav-container {
  float: left;
}

.fts-account-tab-wrapper {
  min-height: 60vh;
  padding: 2.5rem;
  padding-top: 20px;

  hr {
    margin: 2rem 0;
  }

  .row {
    .column {
      .btn {
        margin-left: -2rem;
      }
    }

    .account-info-col-2-fifths {
      flex-basis: 40%;
      max-width: 40%;
      // padding-bottom: 0.85rem;
      // display: flex;
      // flex-direction: column-reverse;
    }
  }

  .account-info-first-row {
    .account-info-col-fifth {
      flex-basis: 20%;
      max-width: 20%;
      margin-bottom: 0.5rem;
    }
  }
}

.fts-account-tab-files-title-wrapper {
  margin-bottom: 0.5rem;

  .fts-account-tab-files-title {
    padding: 1.75rem 2.5rem;

    .fts-title-row {
      margin-bottom: 0;
    }
  }
}

.tab-pane {
  padding-left: unset !important;
  background-color: transparent !important;
  overflow-y: unset !important;

  .card {
    border: 1px solid #e3e7eb;
    border-radius: 4px;
    box-shadow: none;
  }
}

.account-settings-tab {
  box-shadow: none !important;
  width: 184px;

  .nav-item {
    margin-bottom: 0.8rem;

    .nav-link {
      display: flex;
      align-items: center;
      border-radius: $border-radius !important;

      font-size: 1.05rem !important;
      transition: background-color 0.3s ease;

      &.active {
        // background-color: white !important;
        // box-shadow: 0 2px 16px rgba(0, 0, 0, 0.14);
        transform: translateY(0) !important;

        transition: background-color 0.5s ease;

        .fts-icon {
          path {
            stroke: #01b5f5;
            stroke: none;
            fill: #01b5f5;
            stroke-width: 1;
          }
        }

        &:after {
          display: none;
          left: auto !important;
          top: 1.2rem !important;
          right: -1.5rem !important;
        }
      }
    }
  }
}

.account-setting-wrapper {
  .account-settings-tab {
    .nav-item {
      .nav-link {
        &.active {
          &:after {
            top: 100% !important;
            left: 0 !important;
          }
        }
      }
    }
  }
}

#account-info-section {
  .fts-note-header-new,
  .fts-note-header__label > span {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .fts-account-tab-wrapper {
    .account-info-first-row {
      .account-info-col-fifth {
        flex-basis: 33.333%;
        max-width: 33.333%;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .fts-reverse-switch-group {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

@media (max-width: 767.98px) {
  .fts-account-tab-wrapper {
    .account-info-first-row {
      .account-info-col-fifth {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .account-settings-tab {
    .nav-item {
      .nav-link.active {
        &:after {
          display: none;
        }
      }
    }
  }

  .fts-account-tab-wrapper {
    padding: 1.5rem;

    .account-info-first-row {
      .account-info-col-fifth {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .fts-account-remove-btn {
      width: 100%;
      margin-bottom: 1rem;
    }

    .fts-account-save-btn {
      width: 100%;
    }
  }
}
.fts-account-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fts-account-tab-header {
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-bottom: 24px;
  padding: 0 1.5rem;

  &-left {
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    color: #32383a;
  }
  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;

    button {
      gap: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }

    &-container {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .fts-icon {
        stroke: none;
        cursor: pointer;
      }

      &-email {
        padding: 12px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f6f9;
        cursor: pointer;

        .fts-icon {
          path {
            stroke: #000;
          }
        }
      }

      &-data {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 16px;

        p {
          margin: 0;
        }

        &-name {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #32383a;
          cursor: pointer;
        }

        &-message {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #32383a;
          cursor: pointer;
        }
      }
    }
  }
}

@include target-breakpoint-and-smaller(sm) {
  .content-wrapper {
    padding-top: 1rem !important;
  }
  .fts-account-tab-container {
    .card {
      width: 100vw !important;
      border-radius: 0px !important;
      margin-bottom: 0px;
    }
  }
  .fts-account-tab-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 4rem;

    &-left {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    &-right {
      width: 100% !important;
    }
    &-right-container {
      width: 100% !important;
    }
  }

  .acc-list-flex-wrapper-mobile {
    margin-bottom: 100px;
  }
}
