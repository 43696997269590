@import "../core/variables/variables";
@import "../core/responsive/responsive";

$danger: $red;

.listing-setting-wrapper {
  max-width: 100%;

  #section-note {
    .fts-note-header-new {
      display: none;
    }
  }

  .fts-title-row {
    margin-left: -1.5rem !important;
  }
}

.listing-section {
  border: 1px solid $gray-200;
  padding: 1rem;
  margin-top: 1.5rem;
  box-sizing: border-box;

  &.no-border {
    border: none;
    padding: 0;
    margin-top: 2.2rem;
  }

  &.first {
    margin-top: 0;
  }

  &#section-description,
  &#section-note {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.map-title {
  font-size: 1.1rem;

  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: #7d8c91;
}

.weekday-hours-title {

  margin: 0;
  padding-top: 1rem;
  font-size: 1rem;
  color: #888;
}

.weekday-hours-label {
  margin: 0;
  padding-top: 0.3rem;
  font-size: 1rem;
  color: $black;
}

.fts-nav-container {
  float: left;
  transition: all 0.2s ease-in-out;
}

.fts-listing-tab-wrapper-changes {
  background-color: #f5fef4 !important;
}

.fts-listing-tab-wrapper {
  min-height: 60vh;
  padding: 2.5rem;

  .row {
    .column {
      .btn {
        margin-left: -2rem;
      }
    }
  }
}

.listing-settings-tab {
  box-shadow: none !important;
  width: 184px;

  &:not(.edit-mode) {
    .nav-item {
      margin-bottom: 0.8rem;

      .nav-link {
        display: flex;
        align-items: center;
        border-radius: $border-radius !important;

        font-size: 1.05rem !important;
        transition: background-color 0.3s ease;

        &.active {
          // background-color: white !important;
          // box-shadow: 0 2px 16px rgba(0, 0, 0, 0.14);
          transform: translateY(0) !important;

          transition: background-color 0.5s ease;

          .fts-icon {
            path {
              fill: #01b5f5;
              stroke-width: 0.5;
            }
          }

          &:after {
            display: none;
            left: auto !important;
            top: 1.2rem !important;
            right: -1.5rem !important;
          }
        }
      }

      .isPhone {
        &.active {
          .fts-icon {
            path {
              stroke: #01b5f5;
              fill: none;
              stroke-width: 1;
            }
          }
        }
      }
    }
  }

  &.edit-mode {
    .nav-item {
      margin-bottom: 0.8rem;

      .nav-link {
        color: black;
        background-color: transparent !important;
        border-left: 0.5rem solid transparent !important;
        display: flex;
        align-items: center;

        font-size: 1.05rem !important;
        transition:
          border-left,
          color 0.3s ease;

        &.active {
          color: black;
          border-left: 0.5rem solid #ff4f57 !important;
          transform: translateY(0) !important;

          transition:
            border-left,
            color 0.3s ease;

          &:after {
            display: none;
            left: auto !important;
            top: 1.2rem !important;
            right: -1.5rem !important;
          }
        }
      }
    }
  }

  // .nav-item {
  //   .nav-link.active {
  //     background-color: transparent !important;
  //     transform: translateY(0) !important;
  //     &:after {
  //       left: auto !important;
  //       top: 1.2rem !important;
  //       right: -1.5rem !important;
  //     }
  //   }
  // }
}

.upload-image-btn {
  border: 1px solid $primary !important;
  border-radius: 2px;
}

.image-wrapper {
  position: relative;
  margin: 0;
  margin-bottom: 0;
  padding-top: 0.5rem;

  img {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: auto;
  }

  .no-image {
    margin: 0;
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .image-label {
    font-size: 1.1rem;
    color: $gray-400;
    padding-top: 0.5rem;

    &.edit-mode {
      margin: 0.5rem 0 1rem;
    }
  }

  .remove-image-btn {
    display: none;
    margin: 0;
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(0, 0, 0, 0.4);
    transition: color 0.3s ease;

    svg {
      box-sizing: content-box;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      color: rgba(0, 0, 0, 0.7);
      transition: all 0.3s ease;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(0, 0, 0, 0.7);
      transition: all 0.3s ease;
    }
  }

  &.edit-mode {
    margin-bottom: 1.5rem;

    .remove-image-btn {
      display: block;
    }
  }
}

@media (max-width: 1199.98px) {
  .convert-address-btn {
    margin-top: 0;
  }
}

@media (max-width: 767.98px) {
  .listing-setting-wrapper {
    .listing-settings-tab {
      width: auto;
      margin-right: 2rem !important;
    }
  }
}

@media (max-width: 575.98px) {
  .listing-settings-tab {
    .nav-item {
      .nav-link.active {
        &:after {
          display: none;
        }
      }
    }
  }

  .listing-setting-wrapper {
    .listing-settings-tab {
      margin-right: 0 !important;
    }
  }

  .fts-listing-tab-wrapper {
    padding: 1.5rem;
  }

  .listing-section {
    padding: 0.5rem;
  }

  .image-wrapper {
    position: relative;
    margin: 0;

    &.edit-mode {
      margin-bottom: 0.5rem;

      .remove-image-btn {
        display: block;
      }
    }
  }
}

@include target-breakpoint-and-smaller("sm") {
  .card {
    border: none !important;
  }
  .content-wrapper {
    padding: 0 !important;
  }

  .fts-title-row {
    margin-left: -1.5rem !important;

    font-size: 20px;
    line-height: 24px;
  }

  .fts-title-row .col:first-of-type {
    padding-left: 1rem;
  }

  .fts-listing-details-header {
    padding-bottom: 0px !important;
  }

  .listing-section.no-border {
    margin-top: 1.5rem !important;
  }

  .listing-info-actions-mobile {
    display: flex;
    margin-top: 24px;

    .edit-btn-wrapper {
      flex: 1;

      .edit-btn {
        width: 100%;
      }
    }

    .dropdown-wrapper {
      flex: 1;

      .btn {
        width: 100%;
      }
    }

    .dropdown-custom-left {
      margin-left: 65px;
    }
  }

  .listing-info-wrapper {
    margin-top: 24px !important;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .listing-location-info,
    .listing-phone-info {
      font-size: 16px;
      line-height: 18px;
      align-self: flex-start;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    .fts-badge {
      align-self: flex-start;
      margin-left: 0!important;
    }
  }

  .fts-listing-details-header {
    width: 100% !important;
  }

  .listing-actions-mobile {
    display: flex;
    position: fixed;
    bottom: -2px;
    gap: 8px;
    background-color: white;
    width: 100vw;
    padding: 24px;
    z-index: 100;
    border-top: 1px solid #e5e8e9;
  }

  .temporary-image-wrapper-title {
    text-align: center;
    vertical-align: middle;
    padding: 0px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    color: white;
    margin: 0;
  }
}

.listing-section {
  .temporary-image-wrapper-title {
    text-align: center;
    vertical-align: middle;
    padding: 0px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    color: white;
    margin: 0;
  }

  .temporary-image-wrapper-premium {
    background-image: url("../../img/backgrounds/premium-temporary.png");
    background-size: contain;
    height: 245px;
    width: 295px;

    &-title {
      height: 70px;
      line-height: 70px;
      font-size: 17px;
    }
  }

  .temporary-image-wrapper-standard {
    background-image: url("../../img/backgrounds/standard-temporary.png");
    background-size: contain;
    height: 130px;
    width: 295px;

    &-title {
      height: 60px;
      line-height: 60px;
      font-size: 14px;
    }
  }

  .temporary-image-wrapper-basic-plus {
    background-image: url("../../img/backgrounds/basic-plus-temporary.png");
    background-size: contain;
    height: 65px;
    width: 295px;

    &-title {
      height: 35.45px;
      line-height: 35.45px;
      font-size: 11px;
    }
  }
}
