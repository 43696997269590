.fts-contact-us-confirmation-modal {
    width: 580px;

    &-header {
        background-color: #FFFFFF;
        color: #7D8C91;
    }

    .modal-header {
        display: flex;
        justify-content: flex-end;
        background-color: #FFFFFF !important;
        padding: 28px 34px 0 0;
    }

    .modal-body {
        padding: 0;
    }
    .modal-footer {
        border-top: none;
        padding: 37px 22px 37px 22px;
    }

    .btn {
        display: flex;
        width: 100%;
        height: 48px;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 0;
    }

    .row{
        width: 100%;
    }
    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #32383A;
        gap: 15px;

        h2 {

            font-weight: bold;
            font-size: 29px;
            line-height: 32px;
        }

        &-icon {
            height: 62px;
            width: 62px;
            background-color: #E6FCE2;
            border-radius: 50%;
            display: flex;
            padding-top: 23.5px;
            justify-content: center;
        }

        span {
            padding: 0 24px;
        }
    }
}
