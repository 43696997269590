.fts-not-authorized {
  height: 100vh;
  background-color: #fafafc;

  &-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafc;

    &-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 790px;
      padding: 64px;
      background: #fff;
      border-radius: 4px;
      margin-bottom: 6rem;
      box-shadow: 0px 3px 9px #ededf3;
      padding-right: 3rem;
      padding-left: 3rem;
      text-align: center;

      &-buttons {
        display: flex;
        flex-direction: column;
        max-width: 200px;
        gap: 16px;
      }

      .fts-icon {
        stroke: none;
        margin-bottom: 1.5rem;
      }

      h3 {
        margin-bottom: 1rem;
        font-size: 29px;
        line-height: 32px;

      }

      p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 1.5rem;

      }
    }
  }
}

@media (max-width: 768px) {
  .fts-not-authorized {
    &-message {
      &-box {
        width: 100%;

        h3 {
          font-size: 24px;
          line-height: 28px;
        }

        &-buttons {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
