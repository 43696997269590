@import "../../core/variables/variables";
@import "../../core/responsive/responsive";

.fts-listing-item-free {
  background-color: transparent !important;
  border-bottom: 1px solid #e6e6f5 !important;
  box-shadow: none !important;
  padding-bottom: 12px;
  border-radius: 0px !important;

  .collapse-button {
    display: flex;
    align-items: center;
    color: #3f8ceb;
    transition-duration: 0.15s;

    font-size: 16px;

    svg {
      margin-right: 16px;
    }
  }

  .collapsed {
    svg {
      transform: rotate(0deg);
      transition-duration: 0.15s;
    }
  }

  .not-collapsed {
    svg {
      transform: rotate(-90deg);
      transition-duration: 0.15s;
    }
  }

  .collapse-button:hover {
    cursor: pointer;
  }

  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.fts-listing-item {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 9px #ededf3;
  background-color: #fff;
  padding: 32px 32px 0px 32px;
  width: 1150px;
  max-width: calc(100vw - 43px);

  .fts-listing-item-content {
    display: flex;
    justify-content: space-between;

    .fts-listing-item-info {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title-row {
        display: flex;
        align-items: center;

        .title {
          padding-right: 1rem;
          text-decoration: none;
          color: #2c2f36;

          font-size: 24px;
          line-height: 2rem;
          cursor: default;
        }

        .authorized {
          height: 100%;
          background-color: #f4f9fe;
          border-radius: 4px;
          box-shadow: 0px 1px 2px 0px rgba(44, 47, 54, 0.08);
          height: 2rem;
          display: flex;
          align-items: center;

          font-size: 14px !important;
          line-height: 18px !important;
          color: #2c2f36;
          padding: 11px;
          height: 40px;
          cursor: default;

          .card-icon {
            margin-right: 6px;
            svg {
              stroke: none;
            }
          }
          .info-icon {
            margin-left: 8px;
            margin-bottom: 2px;
            align-items: center;
            .fts-icon {
              path {
                stroke: #707786 !important;
              }
            }
          }
        }
      }

      .info-row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        cursor: default;

        .info-row-item {
          display: flex;
          align-items: center;

          font-size: 14px;
          line-height: 17px;
          margin-right: 24px;
          margin-top: 0.6rem;
          color: #2c2f36;

          .text {
            margin-left: 8px;
          }

          .fts-icon {
            path {
              stroke: #2c2f36;
            }
          }
        }
      }

      @include target-breakpoint-and-larger("md") {
        .image-and-description > div {
          img {
            margin-right: 32px;
          }
        }

        .temporary-image-wrapper {
          margin-right: 32px;
        }
      }

      .image-and-description {
        font-size: 0.875rem;
        color: #2c2f36;
        line-height: 19px;
        margin-top: 24px;
        margin-bottom: 1.5rem;

        img:hover {
          cursor: default;
        }

        .description-premium {
          height: 237px !important;
          min-height: 237px !important;
          max-height: 237px !important;
        }

        .description-standard {
          height: 120px !important;
          min-height: 120px !important;
          max-height: 120px !important;
        }

        .description-basic-plus {
          height: 50px !important;
          min-height: 50px !important;
          max-height: 50px !important;
        }

        .description-basic {
          height: 0px !important;
          min-height: 0px !important;
          max-height: 0px !important;
        }

        .description-free {
          height: 0px !important;
          min-height: 0px !important;
          max-height: 0px !important;
        }

        .description {
          margin-left: 0px !important;
          width: 507px !important;

          font-size: 14px !important;
          line-height: 20px !important;
          word-wrap: break-word !important;
          max-height: 240px;
          overflow: hidden !important;

          .ql-align-center {
            text-align: center;
          }

          .ql-align-right {
            text-align: right;
          }

          p {
            line-height: 21px !important;
            margin: 0 !important;
          }

          ul {
            margin-bottom: 0 !important;
          }

          li {
            line-height: 21px !important;
            margin: 0 !important;
          }

          code {
            font-size: 14px !important;
            line-height: 21px !important;
            background-color: transparent !important;
          }
        }

        .description * {
          font-size: 14px !important;
          line-height: 21px !important;

          color: #2c2f36;
          overflow-x: auto;
          background-color: transparent !important;
          margin-bottom: 0px !important;
        }
      }
    }

    .fts-listing-item-buttons {
      height: 100% !important;
      align-items: center !important;
      min-width: 145px;
      margin-left: 108px;
      .contact-button {
        font-weight: bold !important;
        color: white !important;
        background-color: $primary !important;
        width: 145px !important;
        font-size: 16px !important;
      }

      .start-repair-button {
        font-weight: bold !important;
        color: white !important;
        background-color: #3e4d64 !important;
        width: 145px !important;
        font-size: 16px !important;
      }
    }
  }

  .title-no-padding {
    padding-top: 0 !important;
  }
  .temporary-image-wrapper-title {
    text-align: center;
    vertical-align: middle;
    padding: 0px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    color: white;
    margin: 0;
  }

  .temporary-image-wrapper-premium {
    background-image: url("../../../img/backgrounds/premium-temporary.png");
    background-size: contain;
    height: 245px;
    width: 295px;

    &-title {
      height: 70px;
      line-height: 70px;
      font-size: 17px;
    }
  }

  .temporary-image-wrapper-standard {
    background-image: url("../../../img/backgrounds/standard-temporary.png");
    background-size: contain;
    height: 130px;
    width: 295px;

    &-title {
      height: 60px;
      line-height: 60px;
      font-size: 14px;
    }
  }

  .temporary-image-wrapper-basic-plus {
    background-image: url("../../../img/backgrounds/basic-plus-temporary.png");
    background-size: contain;
    height: 65px;
    width: 295px;

    &-title {
      height: 35.45px;
      line-height: 35.45px;
      font-size: 11px;
    }
  }
}

@media (max-width: 767px) {
  .fts-listing-item {
    padding: 1rem;
  }

  .title-row {
    .title {
      font-size: 16px !important;
      line-height: 24px !important;
    }

    .authorized {
      height: 30px !important;
    }
  }
}

@media (max-width: 1025px) {
  .fts-listing-item-buttons {
    display: none !important;
  }

  .info-row {
    flex-wrap: none;
    // flex-direction: column !important;
  }

  .image-and-description {
    flex-direction: column;
    align-items: center;

    .description {
      width: 100%;
    }
  }
}

@include target-breakpoint-and-smaller("sm") {
  .fts-listing-item-bordered {
    border-top: 1px solid #e5e8e9;
  }

  .fts-listing-item-small-buttons {
    padding: 0px 14px;

    &-preview {
      display: flex;
      gap: 16px;
      padding-bottom: 16px;
    }
  }
  .fts-listing-item {
    min-width: 100vw;
    max-width: 100vw;

    .border-none {
      border: none !important;
    }

    .fts-listing-item-content {
      .fts-listing-item-info {
        padding: 0px 14px;

        .title-row {
          border-top: 1px solid #e5e8e9;
          padding-top: 16px;
          .title {
            padding-top: 16px;
            font-size: 20px !important;
            line-height: 24px !important;
          }
        }
      }
    }

    .description {
      display: none;
    }
  }
}
