@import "../../../core/responsive/responsive.scss";

.confirmation-wrapper {
  min-width: 628px;

  &-modal {
    display: flex;
    flex-direction: column;
    padding: 24px;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 16px 24px;

      span {
        padding-top: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 32px;
        color: #32383a;
      }

      svg {
        cursor: pointer;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      padding: 9px 24px;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #32383a;
    }

    &-footer {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      gap: 16px;

      .row {
        width: 100%;
        margin: 0;
        padding: 17px;
        .col-sm-6 {
          padding: 0 7px !important;
        }
      }

      .btn-primary {
        width: 100%;
      }

      .btn-secondary {
        width: 100%;
      }
    }
  }

  .btn-danger {
    background-color: #f36565 !important;
    color: white !important;
    width: 100%;
  }
}

@include target-breakpoint-and-smaller(sm) {
  .modal-content {
    max-width: 90vw;
  }

  .confirmation-wrapper {
    &-modal {
      padding: 24px;
      &-header {
        padding: 0 0 24px 0;
        span {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          padding-top: 0 !important;
        }
      }
      &-content {
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        p {
          margin-bottom: 0 !important;
        }
      }
      &-footer {
        padding: 0;
        margin-top: 24px;
        .row {
          flex-direction: column-reverse;
          gap: 1rem;
          padding: 0px;

          .col-sm-6 {
            padding: 0px !important;
          }
        }
      }
    }
  }
}
