.header-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/img/image/hero-Image.png");

  .beta-tag {
    width: 40px;
    height: 18px;
    color: #000;
    background-color: #fff;
    border-radius: 2px;
    font-size: 10px;
    line-height: 14px;

    padding: 2px 8px;
    margin-left: 1rem;
  }
}

.header-wrapper > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 50px;
  font-size: 21px;
  line-height: 28px;
  position: relative;
  color: white !important;

  .donwnload-pdf-report-button {
    //height: 64px;
    //min-width: 200px;
    background-color: #fff !important;
    color: #32383a !important;
    font-size: 16px;
    line-height: 20px;
  }
  .donwnload-pdf-report-button:hover {
    background-color: #fff !important;
    color: #32383a !important;
  }

  .donwnload-pdf-report-button:focus {
    background-color: #fff !important;
    color: #32383a !important;
  }
}

.headerContentLeft {
  display: flex;
}

.account {
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  font-size: 21px;
  line-height: 28px;

  .font-bold {
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
  }
}

.listing {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(250, 250, 252, 0.5);
  font-size: 21px;
  line-height: 28px;

  .font-bold {
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
  }
}

.renewalDue {
  padding-left: 40px;
  border-left: 1px groove #fff;
  display: flex;
  flex-direction: column;
  //@apply flex flex-col;
}

@media (max-width: 600px) {
  .donwnload-pdf-report-button {
    height: 48px !important;
  }
}
@media (max-width: 768px) {
  .header-wrapper > .container {
    padding-bottom: 62px;
    padding-top: 62px;
  }
  .header-wrapper > div,
  .headerContentLeft {
    > .listing {
      border: unset;
      padding-left: unset;
      margin-top: 24px;
    }

    flex-direction: column;
    align-items: flex-start;
    .donwnload-pdf-report-button-wrapper {
      margin-top: 24px;
      width: 100%;
      button {
        width: 100%;
        margin-left: 0px !important;
      }
    }
  }
}
//@media (max-width: 600px) {

//  .wrapper > div {
//    flex-direction: column;
//    margin-top: auto;
//    justify-content: initial;
//    padding: 24px;
//    padding-bottom: 40px;
//    align-items: initial;
//    font-size: 18px;
//    line-height: 24px;
//    @apply relative min-h-[375px];
//  }
//
//  .headerContentLeft {
//    flex-direction: column;
//    margin-top: auto;
//  }
//
//  .account {
//    padding-bottom: 16px;
//    border-bottom: 1px groove #fff;
//  }
//
//  .renewalDue {
//    padding-left: 0px;
//    padding-top: 16px;
//    border-left: none;
//  }
//}
