@import "../../core/variables/variables";

.ql-editor * {

  color: #2c2f36 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  background-color: transparent !important;
}

.select__control {
  min-height: 48px !important;
  background-color: #fbfcfd !important;
  border-radius: 2px;

  &--is-disabled {
    background-color: transparent;
  }
}

.changed-checkbox {
  .fts-checkbox-label {
    color: #3cb527;
  }
}

.duplicate-promotion-button {

  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #242d34;

  .fts-icon {
    path {
      stroke: #242d34;
    }
    margin-right: 11px !important;
  }
}

.duplicate-promotion-button:hover {
  cursor: pointer;
}

.filter-section {
  > input {
    height: 44px;
  }
}

.fts-note-absolute {
  position: absolute;
  // top: 5px;
  bottom: 0px;
  right: 10px;
  left: 10px;
  box-shadow: 0px 0px 5px 3px #e2e2e2;
}

.fts-note-header-new {
  display: flex;
  align-items: center;
  background-color: #fafbfc; ////
  padding: 8px 10px;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  &__info {
    margin-left: auto;
  }
  &__label {
    display: flex;
    justify-self: left;
    align-items: center;
  }
}

.fts-note-header {
  display: flex;
  align-items: flex-end;
  // margin-bottom: 0.2rem;
  padding: 0.2rem 0.2rem;

  &__info {
    margin-left: auto;
  }
}

.note-type-wrapper {
  display: flex;
  align-items: flex-end;

  > div {
    &:last-of-type {
      margin-left: 1rem;
      flex: 1;
      min-width: 150px;
    }
  }
}

.form-group {
  margin-bottom: 1.5rem;

  label {

    font-weight: 400;
    font-size: 0.96rem;
    line-height: 14px;
    color: #7d8c91;
    margin-bottom: 0.25rem;

    &.custom-control-label {
      margin-bottom: 0;
    }

    &.disabled {
      font-size: 0.96rem;
      color: #7d8c91;
      margin-bottom: 0;
    }
  }
}

.form-control {
  font-size: 1.1rem;


  &::placeholder {

    font-size: 1.1rem !important;
    color: #757575 !important;
  }

  &:focus {
    border-color: $primary !important;
    background-color: #fff;
    box-shadow: none;
  }

  &:disabled,
  &:not(.fts-date-picker)[readonly],
  &.disabled {
    // background-color: #fff;
    background-color: transparent;
    border-color: transparent !important;
    border-left-width: 0 !important;
    padding: 0;
    padding-left: 0;
    min-height: 25px;
    height: 25px;
    font-size: 1rem;

  }

  &.fts-date-picker {
    height: 48px !important;
    background-color: #fbfcfd !important;

    &:focus {
      background-color: #fff !important;
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
    }

    &::placeholder {
      color: #757575;
    }
  }

  &.listing-title {
    font-size: 1.7rem;
    padding: 0.5rem 0;
    padding-left: 1rem;
    background-color: #f3f3f3;
    color: #222;
    border: 0 !important;
    box-shadow: none;
    margin: 0 0 1.5rem;

    &:disabled,
    &:read-only,
    &.disabled {
      background-color: #fff;
      padding-left: 0;
      font-size: 2.2rem;
      height: 3rem;
    }

    &::placeholder {
      font-size: 1.7rem;
      color: #535353;
    }
  }
}

.form-group {
  .fts-date-picker {
    background-color: #fbfcfd !important;
  }
}

.fts-plate-picker-input-preview {
  .fts-date-picker {
    background-color: #fff !important;
  }
}

textarea.form-control {

  font-size: 1rem;
  resize: none;
  // border: 1px solid #d8d8d8 !important;

  &:disabled,
  &[readonly],
  &.disabled {

    font-size: 1rem;
  }
}
textarea.new-note {
  border: 1px solid #d8d8d8 !important;
}

.Create-new-account-padding {
  padding: 0px 24px 24px 20px;
  margin: 0px;
}
textarea.form-control:disabled,
textarea.form-control[readonly],
textarea.form-control.disabled {
  height: auto;
  max-height: 1000px;
}

.formik-validation-error {
  color: #f20933;
  font-size: 12px;
  margin-top: 5px;
}

.formik-input-value {
  margin-top: 5px;
  //word-break: break-all;
}

.invalid-formik-input {
  border-color: #f20933 !important;
  &:focus {
    border-color: #f20933 !important;
  }
}

.hour-input-label {

  margin: 0;
  padding-top: 6px;
  font-size: 1.2rem;
}

.hour-input-addon {
  max-width: max-content;
}

.form-group.fts-email {
  margin-bottom: 0;
}

.fts-double-switch-container {
  .row {
    margin-bottom: 0.5rem;
  }
}

.fts-reverse-switch {
  font-size: 1.2rem;

  .custom-switch {
    align-items: center;
    display: flex;
    margin-right: 0;
  }

  .switch-label {
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: 0.01rem;
    color: #626262;
    font-size: 14px;
  }
}

.fts-optin-new {
  display: flex;
  align-items: center;

  &-switch {
    margin-left: auto;
  }

  span.switch-label {
    font-size: 11px;
  }

  .custom-switch {
    align-items: center;
    display: flex;
    // flex-direction: row-reverse;
    margin-right: 15px;
  }

  .custom-switch .custom-control-label {
    height: 1rem;
    width: 2.5rem;
  }

  .custom-switch .switch-label {
    padding-left: 0;
    padding-right: 0.8rem;
    padding-left: 0.5rem;
  }

  .custom-switch .custom-control-label::before {
    height: 1rem;
    width: 2.5rem;
  }

  .custom-switch .custom-control-label::after {
    height: 10px;
    width: 10px;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.5rem);
  }
}

.fts-optin {
  display: flex;
  align-items: center;

  &-switch {
    margin-left: auto;
  }

  span.switch-label {
    font-size: 11px;
  }

  .custom-switch {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
  }

  .custom-switch .custom-control-label {
    height: 1rem;
    width: 2.5rem;
  }

  .custom-switch .switch-label {
    padding-left: 0;
    padding-right: 0.8rem;
  }

  .custom-switch .custom-control-label::before {
    height: 1rem;
    width: 2.5rem;
  }

  .custom-switch .custom-control-label::after {
    height: 10px;
    width: 10px;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.5rem);
  }
}
#price-padding {
  margin: auto 1.4rem auto 0;
}
@media (max-width: 575.98px) {
  // .form-group.mb-0 {
  //   margin-bottom: 1.5rem !important;
  // }
}

@media (max-width: 767.98px) {
  .form-group.fts-email {
    margin-bottom: 1rem;
  }

  .hour-input-label {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1199.98px) {
  .form-group {
    label {
      font-size: 1rem;

      &.disabled {
        font-size: 0.9rem;
      }
    }
  }

  .form-control {
    font-size: 1rem;
    min-height: 40.94px;

    &.listing-title {
      font-size: 1.5rem;
    }
  }
}
