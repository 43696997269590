.FTS-chart-listing-info-wrapper {
  padding-bottom: 24px;
}
.FTS-chart-listing-info-wrapper-top {
  display: flex;
  justify-content: space-between;
}

.FTS-chart-listing-info-name {
  border-left: 6px solid #ce1b3b;
  margin-left: -2.5rem;
  padding-left: 2.1rem;
  font-size: 20px;
  line-height: 24px;
}
.FTS-chart-listing-type-red {
  color: white;
  background-color: #ce1b3b;
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  padding: 2px 8px;
  margin-bottom: 3px;
}

.FTS-chart-listing-type-gray {
  color: #000;
  background-color: #f2f6f9;
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  padding: 2px 8px;
  margin-bottom: 3px;
}

.FTS-chart-listing-info-preview {

  display: flex;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  background: #f2f6f9;
  gap: 8px;
  width: 200px;
  height: 48px;
  cursor: pointer;
}
