.complete-profile-layout {
  height: 100%;

  .complete-profile-navigation {
    height: 128px;
    background-color: #f30933;
    display: flex;
    align-items: center;
    padding-left: 40px;
  }
  .complete-profile-wrapper {
    padding-top: 46px;
    padding-right: 156px;
    padding-left: 156px;
    width: 100%;
    padding-bottom: 3rem;
    background-color: #fafafc;

    .form-header {
      padding-bottom: 40px;
    }

    .form-header > h2 {
      font-size: 54px;
      line-height: 72px;
      font-weight: bold;
      margin-bottom: 1rem;

      color: #32383a;
    }

    .form-header > p {
      font-size: 1rem;
      line-height: 18px;
      color: #32383a;
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 768px) {
  .complete-profile-wrapper {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .form-header {
    padding: 0px 2rem;
    .backButton {
      margin-bottom: 1rem;
    }
  }

  .form-header > h2 {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .form-header > p {
    font-size: 16px !important;
    line-height: 18px !important;
  }
}
