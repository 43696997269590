$title-row-offset-left: 2.5rem;
$title-row-border-width: 0.5rem;

.fts-title-row {
  margin-left: (-$title-row-offset-left) !important;
  margin-bottom: 1.5rem;

  .col h2 {
    margin: 0;
  }

  .col h4 {
    margin: 1px 0 1px 0;
  }

  .col:first-of-type {
    padding-left: ($title-row-offset-left - $title-row-border-width);
    border-left: $title-row-border-width solid #ce1b3b !important;
  }

  &.no-marker {
    .col:first-of-type {
      padding-left: ($title-row-offset-left - $title-row-border-width);
      border-left-color: transparent;
    }
  }

  .go-to:hover {
    cursor: pointer;
  }
}

.fts-title-row:hover {
  cursor: default;
}
