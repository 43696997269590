.navigation-create-listing-btn {
  display: none;
  padding-top: 16px;
  background-color: #ce1b3b;

  .btn-white {
    margin-left: 15px;
    width: calc(100vw - 32px);
    z-index: 1000;
    border-radius: 4px;
    height: 48px;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .fts-icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;

      path {
        stroke: #32383a !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .navigation-create-listing-btn {
    display: unset;
  }
}
