@import "../core/responsive/responsive";

.password-hints {
  &-header {
    font-weight: 600;
  }
}

.change-password-footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 1.5rem;
}

@include target-breakpoint-and-smaller("sm") {
  .password-hints {
    &-header {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
    ul {

      padding: 0px 20px;
      margin-top: 20px;

      li {
        font-size: 16px;
        line-height: 18px;
      }

      li:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  .change-password-footer {
    gap: 16px;
    .btn {
      margin: 0;
      flex: 1;
      width: 100%;
    }
  }
}
