@import "../core/responsive/responsive";

.fts-account-deactivated {
  height: 100vh;
  background-color: #fafafc;

  &-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafc;

    &-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      height: 50%;
      background: #fff;
      border-radius: 4px;
      margin-bottom: 6rem;
      box-shadow: 0px 3px 9px #ededf3;
      padding-right: 3rem;
      padding-left: 3rem;
      text-align: center;

      .fts-icon {
        stroke: none;
        padding-bottom: 5px;
      }

      h3 {
        margin-bottom: 1rem;
      }

      p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 1.5rem;

      }
    }
  }

  &-icon-wrapper {
    padding: 20px;
    background-color: #f2f3f4;
    border-radius: 50%;
    margin-bottom: 1.5rem;
  }
}

@include target-breakpoint-and-smaller("sm") {
  .fts-account-deactivated {
    height: calc(100vh - 80px);
    &-message {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fafafc;

      &-box {
        width: 80%;
        margin-bottom: unset;
        padding: 24px;
        height: max-content;
      }
    }
  }
}
