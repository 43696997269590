.fts-renew-banner {
  background-color: #ce1b3b;
  padding: 8px 0px;

  position: sticky;
  bottom: 0px;
  width: 100%;
  z-index: 1000;

  .container {
    .fts-renew-banner-desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .fts-renew-banner-mobile {
      display: none;
      flex-direction: column;

      &-title {
        width: 100%;
        display: flex;
        align-items: center !important;
        justify-content: space-between;

        h3 {
          color: #fff;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          margin: 0;
          padding: 0;
        }

        &-chevron {
          cursor: pointer;
          path {
            stroke: #fff !important;
          }
        }
      }

      .renew-now-btn {
        width: 100%;
        margin-top: 24px;
        //height: 48px;
        color: #32383a !important;
      }

      .renewal-date-amount {
        margin-top: 24px;
        width: 100%;

        .renewal-date {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          margin-right: 0 !important;
          min-width: unset !important;
          border-right: none !important;
        }

        .renewal-amount {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px !important;
        }

        .light {
          font-size: 16px;
          line-height: 18px;
        }

        .bold {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }

    .left {
      height: 64px;
      display: flex;
    }

    .renewal-date {
      display: flex;
      align-items: center;

      margin-right: 60px;
      padding-right: 60px;
      height: 100%;
      border-right: 1px solid rgba(250, 250, 252, 0.5);
      white-space: nowrap;
    }

    .renewal-amount {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .light {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #fff !important;
      margin-right: 8px !important;
    }

    .bold {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #fff;
    }

    .renew-now-btn {
      background-color: #fff !important;
      color: #ce1b3b !important;
      border-radius: 0.25rem;
      //height: 64px;
      //width: 150px;
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }
}

@media (max-width: 1200px) {
  .fts-renew-banner-desktop {
    display: none !important;
  }
  .renewal-date {
    padding-right: 0px !important;
  }
  .fts-renew-banner-mobile {
    display: flex !important;
  }
}
@media (max-width: 768px) {
  .fts-renew-banner {
    padding: 18px 0px;
  }
}
