.FTS-Account-Requested-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  padding: 40px;
  background-color: #fafafc;

  h3 {
    padding-bottom: 40px;

    font-weight: 600;
    color: #32383a;
    font-size: 24px;
    line-height: 28px;
  }
}

.FTS-Account-Requested-message-heading-mobile {
  display: none;
}

.FTS-Account-Requested {
  display: flex;
  padding: 16px;
  width: 916px;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 3px 9px #ededf3;
  align-items: center;

  &-left {
    display: flex;
  }

  &-message {
    display: flex;
    flex-direction: column;
  }

  &-right {
    display: flex;
    align-items: center;

    &-action {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0px 27px;

      &-text {
        padding-left: 7px;
      }
    }

    &-action-approve {
      color: #00b5f4;
    }
  }
}

.FTS-Account-Requested-Icon-wrapper {
  margin-right: 12px;
  svg {
    stroke: none;
  }
}
.FTS-Account-Requested-Empty-State-Wrapper {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafc;
}

@media (max-width: 1000px) {
  .FTS-Account-Requested-message-heading-mobile {
    display: inline;
    padding-top: 12px;
    padding-bottom: 26px;
  }

  .FTS-Account-Requested {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &-left {
      align-items: center;
    }

    &-right {
      align-items: flex-start;

      &-action {
        margin-left: 0px;
      }
    }
  }

  .FTS-Account-Requested-message-info {
    display: none;
  }
}
