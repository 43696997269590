.fts-link-successfull {
  &-heading {
    padding-bottom: 24px;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #32383a;
  }

  &-button {
    margin-top: 24px;
    width: 100%;
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px;
    max-width: 500px;

    .fts-link-successful-svg-wrapper {
      padding-bottom: 40px;

      svg {
        stroke: none;
      }
    }
  }

  &-info {
    text-align: center;
  }
}
