@import "../core/variables/variables";

.acc-list-flex-wrapper {
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 15rem);
  // padding-bottom: 2rem;
  max-width: 100vw;
  .nav-vertical {
    overflow: auto;
    flex: 1;
    // padding-bottom: 2rem;
  }

  .brand-border {
    height: 4px;
    margin-left: -1px;
    margin-right: -1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .brand-border-user {
    background-color: #7d8c91;
  }

  .brand-border-vendor {
    background-color: #f30933;
  }

  .card {
    box-shadow: 0px 3px 9px 0px rgba(237, 237, 243, 1);
    border-radius: 4px;
  }
}

.account-box-badge {
  padding: 3px 6px !important;
  background-color: #fff !important;
  color: #191c1d;
  border: 1px solid #e5e8e9;
  box-sizing: border-box;
  border-radius: 24px;
  margin: 0;
  margin-top: 7px !important;

  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: fit-content;
}

.account-type-info {
  margin-left: -16px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-border-top {
  .card {
    border-top: none !important;
  }
}

.acc-info-header {
  display: flex;
  align-items: center;

  h2 {
    font-weight: 600;
    font-size: 23px;
    line-height: 25px;
    padding-bottom: 19px;
  }

  svg {
    stroke: none;
  }

  span:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2px;
  }
}

.edit-btn-wrapper {
  .btn {
    padding-right: 0;
  }
}

.edit-btn {
  > .edit-btn-label {
    color: #32383a !important;
    margin-left: 10px;
  }
}

.transparent {
  background-color: transparent !important;
}

.duplicate-listing-btn {
  > .duplicate-listing-btn-label {
    color: $primary !important;
  }
}

.listing-info-row {
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: "16px";
  color: #16171a;
  padding-bottom: 2rem;
  margin-bottom: -2rem;
  margin-top: 0.2rem;

  svg {
    margin-right: 0.5rem;
    stroke: #7d8c91;
    stroke-width: 1.5px;
  }

  .listing-location-info {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
}

.fts-reassign-acc-list-modal {
  .modal-body {
    padding: 0;
  }
  .fts-reassign-acc-list-modal-body {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    color: #32383a;

    .fts-reassign-acc-list-modal-body-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .fts-reassign-acc-list-modal-search {
      margin-top: 18px;
      margin-bottom: 8px;
    }

    .form-control-position {
      margin-top: 2px;
    }

    .fts-reassign-acc-list-modal-title {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 12px;

      .fts-close-btn {
        background-color: transparent;
        color: #7d8c91;
      }
    }

    .reassign-modal-message {
      font-size: 14px;
      line-height: 16px;
    }

    .reassign-row {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ededed;
      padding: 18px;
      padding-left: 0;
      color: #32383a;

      font-size: 14px;
      line-height: 16px;

      .vx-checkbox--check {
        background-color: #647074 !important;
      }
      .vx-checkbox-con input:checked ~ .vx-checkbox {
        border-color: #647074;
      }

      .reassign-checkbox {
        margin-right: 10px;
      }
    }
  }
}

.working-hours-switch {
  .custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: #98ca86 !important;
  }
  .custom-switch .custom-control-label::after {
    border-radius: 20px;
  }
}

.field-changed {
  color: #3cb527 !important;
}

.about-listing {
  padding-top: 1rem !important;
}

.section-changed {
  display: content;
  background-color: #f2fbf0;
  margin: 0 -42px;
  padding: 0 45px;

  .about-listing {
    padding-top: 1rem;
  }

  .listing-section.no-border {
    margin-top: 0;
    padding-top: 2.2rem;
  }

  .fts-divider {
    margin: 0;
    padding: 0.8rem 0 1.15rem;
    background-color: #f2fbf0;
  }

  .category-wrapper {
    background-color: #f2fbf0;
  }
}

.section-changed:first-child {
  padding-top: 1.8rem;
}

.section-not-change {
  padding-top: 1.8rem;
}

.fts-listing-tab-wrapper-right {
  padding: 0;
}

.fts-label-disabled {
  margin-bottom: 0;
  font-size: 0.96rem;
  color: #7d8c91;
  transition: all 0.3s ease;
}

.fts-input-value {
  margin-top: 5px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.account-listing-header-btn {
  // width: 3rem;
  // height: 3rem;
  box-sizing: border-box;
  border-radius: 0.125rem;
  padding: 0.25rem;
  margin-right: 1rem;
  color: $gray-400 !important;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    color: $gray-400 !important;
  }

  &.preview-listing-btn {
    width: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    .preview-listing-btn-label {
      vertical-align: middle;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.acc-list-primary-btn {
    width: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-color: $primary !important;
    color: white !important;
  }
}

.acc-list-files-header-item,
.acc-list-files-header-item-w-btn {
  display: flex;
  padding-right: 1rem;
}

.acc-list-files-header-item {
  flex: 1;

  .form-group {
    width: 100%;
  }
}

.acc-list-action-bar-wrapper {
  // margin: -0.5rem 0 1.5rem 3rem;
  flex: 1;
}

.acc-list-sidebar-wrapper {
  display: block;
  width: 25vw;
  margin-left: 0;
  margin-right: -25vw;
  transition: all 0.3s ease;
  max-height: 100vh;
  min-height: 500px;

  .acc-list-animation-wrapper {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.show {
    margin-right: 0;
    margin-left: 1.5rem;
    transition: all 0.3s ease;

    .acc-list-animation-wrapper {
      opacity: 1;
      transition: opacity 0.3s ease;

      .fts-note-small {
        width: 96%;
      }

      .stickyNote {
        background-color: #ffe999;
      }
      .fts-todo-small {
        width: 96%;
      }
      .row {
        margin-left: 0;
      }
    }
  }

  .card {
    .card-body {
      padding: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100vh;
    }
  }

  .acc-list-sidebar-header {
    .acc-list-sidebar-header-col {
      &::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 1px;
        border-right: 1px solid #e5ebed;
        height: 70%;
        margin-top: 5%;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }

    .acc-list-sidebar-header-btn {
      width: 100%;
      display: flex;
      color: #a5a5a5;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0;

      &.checked {
        color: $gray-700;

        .header-btn-label {
          color: $fts-dark-gray;
        }
      }

      .header-btn-check {
        display: inline-flex;
      }

      .header-btn-label {
        margin-top: 0.6rem;
        text-align: start;
        font-size: 0.9rem;

        flex: 1;
        display: flex;
        align-items: center;

        span {
          padding-right: 0.5rem;
          padding-left: 0.25rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.togglable {
    padding: 1.5rem;
    width: auto;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .acc-list-flex-wrapper {
    display: block;
    max-width: 100vw;

    .nav-vertical {
      overflow: auto;
      padding-bottom: 2rem;
    }
  }

  .acc-list-card {
    margin: auto;
  }

  .acc-list-card-wrapper {
    padding-right: 3rem;
    margin-right: -3rem;
  }
}

.nav-collapse-button {
  margin-left: 4px;
}

.nav-collapse-button:hover {
  cursor: pointer;
}

.collapsed-button {
  svg {
    transform: rotate(180deg);
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 1199.98px) {
  .acc-list-sidebar-wrapper {
    display: none;
  }
  .acc-list-sidebar-wrapper {
    width: 30vw;
    margin-right: -30vw;
  }
}
