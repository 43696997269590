.client-card-section {
  background-color: #ce1b3b;
  position: relative;
  padding-top: 4rem;
  width: 100%;
  // margin-bottom: 40px;

  .container {
    overflow: hidden;
    height: 100%;

    .grid {
      display: grid;
      height: 100%;

      .client-card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        color: #fff;

        h2 {

          font-weight: 700;
          color: #fff;
        }

        p {
          color: #fff;
          font-size: 1rem;
          line-height: 1.5rem;
          margin-top: 1.5rem;
        }

        .links {
          margin-top: 3.5rem;
          font-weight: 400;

          .apply-now-btn {
            background-color: #fff !important;
            color: #32383a !important;
            border-radius: 0.25rem;
            height: 64px;
            width: 200px;
            font-size: 16px !important;
            line-height: 24px !important;
          }

          .learn-more-btn {
            background-color: transparent !important;
            color: #32383a !important;
            border: 1px solid #fff !important;
            color: #fff !important;
            border-radius: 0.25rem;
            height: 64px;
            width: 200px;
            margin-left: 24px;
            font-size: 16px !important;
            line-height: 24px !important;
          }
        }
      }

      .image {
        display: flex;
        position: relative;
        margin-top: 3.5rem;
        justify-content: center;
        align-items: flex-end;
      }
    }
  }
}

.client-card-section:before {
  content: "";
  width: 50%;
  height: 100%;
  background-position: bottom right;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
}

@media (min-width: 1024px) {
  .client-card-section {
    height: 505px;
    margin-bottom: 80px;

    .grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .client-card-content {
      h2 {
        line-height: 58px;
      }
    }

    .image {
      margin-top: 0;
      justify-content: flex-end;
    }
  }

  .client-card-section:before {
    background-image: url("../../../img/backgrounds/red-bg-element.svg");
  }
}
@media (max-width: 1024px) {
  .client-card-section {
    .client-card-content {
      .links {
        width: 100%;
        display: flex !important;
        flex-direction: column !important;

        .learn-more-btn {
          margin-top: 24px;
          margin-left: 0 !important ;
        }

        .btn {
          width: 100% !important;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .client-card-section {
    .client-card-content {
      h2 {
        font-size: 44px;
      }
    }
  }
}
