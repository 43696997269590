.account-box-hover:hover {
  cursor: pointer;
}

.listing-nav-account-box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: #f2f5f9;
  width: 247px;
  margin-left: -2.8rem;
  padding-left: 2.8rem;
  padding-right: 3rem;
  padding-top: 17px;
  padding-bottom: 17px;
  word-break: break-word;

  svg {
    stroke: none;
    stroke-width: 5px !important;
  }

  .account-link-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .account-box-badge {
    padding: 4px 7px !important;
    margin-bottom: 7px !important;
    box-sizing: border-box;
    border-radius: 24px;
    margin: 0;

    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
  }

  .vendor {
    color: #01b5f5;
    border: 1px solid #01b5f5;
  }

  .user {
    color: #7d8c91;
    border: 1px solid #7d8c91;
  }

  .nav-company-name {
    font-size: 14px;
    line-height: 16px;
  }
}

.listing-naw-account-box:hover {
  cursor: pointer;
}

.account-box-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  width: max-content;

  .icon-left {
    margin-right: 1rem;
  }

  .icon-right {
    display: flex;
    align-items: center;
    margin-left: 6.6px;
    margin-bottom: -0.2rem;
    svg {
      stroke-width: 1.5px;
    }
  }

  .company-name {

    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #242d34;
  }

  .gray {
    display: flex;
    align-items: center;
    color: #647074;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: -0.2rem;
  }
}
