.FTS-chart-wrapper {
  border-radius: 4px;
  width: 100%;
  overflow: auto;
  min-height: 450px;
  overflow: hidden;
  border: 1px solid #e5e8e9;
  background-color: #fff;
}

.new-wrapper {
  min-height: unset !important;
  height: auto !important;
  // background: #ffffff;
  // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.141472);
  border-radius: 4px;
}

.FTS-chart {
  max-height: 400px;
  min-height: 200px;
  padding: 20px;
}

.labelWrapper {
  // @apply flex justify-between mb-[0.5rem];
  display: flex;
  justify-content: space-between;
}

.FTS-chart-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #e5e8e9;
  padding: 12px;
}

.label {
  font-size: 14px;
  line-height: 16px;
  color: #97a3a7;
  // @apply font-futura-medium;
}

.amount {
  font-size: 24px;
  line-height: 24px;
  color: #000;
}

@media (max-width: 600px) {
  .FTS-chart-wrapper {
    margin-bottom: 10px;
    border: none;
  }

  .FTS-exposure-chart-wrapper {
    overflow: auto;
  }

  .FTS-chart {
    min-width: 800px;
  }

  .FTS-exposure-chart-legend {
    padding: 16px 0px;
    border-top: 1px solid #e5e8e9;
    justify-content: space-around;
  }

  .FTS-exposure-chart-legend > div:last-of-type {
    margin: 0px !important;
  }
}

.FTS-exposure-chart-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
}

.spinner-border-sm {
  border-width: 0.1em;
}
