.fts-individual-listings-performance {
  .ag-row {
    cursor: default !important;
  }
  .container {
    padding-top: 120px;
    padding-bottom: 96px;
  }

  .fts-listings-actions-search-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .fts-listings-actions-search {
      min-width: 320px;
      width: 320px;
      background: #fbfcfd;
      border: 1px solid #e3e7eb;
      border-radius: 4px;
      padding: 8px 8px 8px 10px;
      height: 48px;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      color: #2c2f36;
    }

    .view-account-report-btn {
      height: 56px !important;
      min-width: 200px;
    }
  }

  .card {
    padding: 32px 24px;
    box-shadow: 0px 3px 9px #ededf3;
    border-radius: 4px;

    .ag-row {
      background-color: transparent !important;
      border-left: none !important;
    }

    .ag-grid-actions {
      justify-content: flex-end !important;
    }

    .filter-section {
      margin-bottom: 1rem;

      input {
        width: 320px !important;
      }
    }

    .pagination {
      margin-top: auto;
    }
  }
}

.fts-individual-listings-performance-hidden {
  display: none;
}

@media (max-width: 575px) {
  .fts-individual-listings-performance {
    .container {
      .card {
        .fts-nav-tabs {
          width: calc(100vw - 4.5rem) !important;
          overflow-x: auto !important;
          overflow-y: hidden !important;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .fts-individual-listings-performance {
    .container {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }

    .fts-listings-actions-search {
      width: 100% !important;
    }

    &-title {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 32px;

      h2 {
        font-size: 20px;
        line-height: 24px;
      }

      .view-account-report-btn {
        height: 48px !important;
        margin-top: 24px;
        width: 100%;
      }
    }

    .card {
      margin-left: -1rem;
      margin-right: -1rem;

      .ag-grid-actions {
        width: 100%;
        justify-content: unset;
        margin-right: 0px !important;
        margin-left: 2rem;

        .filter-section {
          margin-right: 0px !important;

          input {
            width: unset !important;
          }
        }
      }

      .fts-pagination {
        flex-direction: column;
        width: 100%;

        .sort-dropdown {
          width: 100%;
          margin-bottom: 1.5rem;
          margin-right: 0px !important;

          svg {
            position: absolute;
            right: 1rem;
            top: 1.15rem;
          }
        }

        .pagination {
          width: 100%;
        }
      }
    }
  }
}
