@import "../core/responsive/responsive";

.fts-email-unsubscribe {
  height: 90vh;
  background-color: #fafafc;

  form {
    width: 100%;
  }

  &-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafc;

    &-box {
      min-width: 588px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 588px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 9px #ededf3;
      padding: 92px 102px;
      text-align: center;

      .fts-icon {
        stroke: none;
        margin-bottom: 1.5rem;
      }

      h3 {
        margin-bottom: 1rem;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 1.5rem;

        color: #32383a;
      }
    }
  }

  &-checkboxes {
    padding: 20px 0px;
  }

  &-checkbox {
    padding: 20px 0px;
  }

  &-checkbox:first-of-type {
    border-bottom: 1px solid #e5e8e9;
  }

  &-form-error {
    text-align: start;
    font-size: 14px;

    color: red;
    padding-top: 5px;
  }

  &-input {
    width: 100%;
  }

  &-button {
    width: 100%;
  }
}

@include target-breakpoint-and-smaller("sm") {
  .fts-email-unsubscribe {
    &-message {
      padding: 24px;

      &-box {
        min-width: 80% !important;
        padding: 24px;
      }
    }
  }
}
