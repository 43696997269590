@import "../../core/variables/variables";

// ======================
// FTS Custom Checkbox
// ======================

$fts-checkbox-radio-border-width: 1px; // 2px;
$fts-checkbox-border-radius: 2px; // 3px;
$fts-checkbox-radio-border-color: #7d8c91; // $gray-600;
$fts-checkbox-box: 20px;
$fts-checkbox-box-sm: 32px;
$fts-checkbox-box-lg: 24px;

$fts-checkbox-bg-color: $fts-dark-gray;

.fts-checkbox-con {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0;
  color: $fts-checkbox-bg-color !important;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 200;
    cursor: pointer;
    top: 0;
    &:checked {
      ~ .fts-checkbox {
        transform: rotate(0deg);
        border-color: $fts-checkbox-bg-color;
        .fts-checkbox--check {
          transform: translate(0);
          // background-color: $primary;
          .fts-icon {
            transform: translate(0);
            line-height: 1.2;
            opacity: 1;
          }
        }
        &.fts-checkbox-sm {
          .fts-checkbox--check .fts-icon {
            font-size: 0.75rem;
          }
        }
        &.fts-checkbox-lg {
          .fts-checkbox--check .fts-icon {
            font-size: 1.2rem;
          }
        }
      }
    }
    &:active:checked + .fts-checkbox {
      .fts-checkbox--check {
        transform: translate(3px);
        // background-color: $fts-checkbox-bg-color;
        .fts-icon {
          opacity: 1;
          transform: translate(1px);
        }
      }
    }
    &:disabled {
      cursor: default;
      pointer-events: none;
      + .fts-checkbox {
        cursor: default;
      }
      ~ .fts-checkbox-label {
        font-size: 1rem;
      }
    }
  }
  .fts-checkbox {
    cursor: pointer;
    position: relative;
    min-width: $fts-checkbox-box;
    min-height: $fts-checkbox-box;
    width: $fts-checkbox-box;
    height: $fts-checkbox-box;
    border-width: $fts-checkbox-radio-border-width;
    border-style: solid;
    border-color: $fts-checkbox-radio-border-color;
    border-radius: $fts-checkbox-border-radius;

    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2);
    padding: 1px;

    transform: rotate(-90deg);
    transition: all 0.2s ease;
    overflow: hidden;
    margin-right: 0.5rem;
    &.fts-checkbox-sm {
      min-width: $fts-checkbox-box-sm;
      width: $fts-checkbox-box-sm;
      height: $fts-checkbox-box-sm;
    }
    &.fts-checkbox-lg {
      width: $fts-checkbox-box-lg;
      height: $fts-checkbox-box-lg;
    }
    .fts-checkbox--check {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      left: 0;
      transform: translate(100%);
      transform-origin: right;
      transition: all 0.2s ease;
      z-index: 10;
      .fts-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        opacity: 0;
      }
    }
  }

  .fts-checkbox-label {

    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
