.fleet-carrier-form-wrapper {
    border-radius: 4px;
    box-shadow: 0 3px 9px 0 rgba(237, 237, 243, 1);
    background-color:white;
    padding: 2rem;

    .form {
        padding-top: 3rem;
    }
}
