.info-message-small {
  padding: 16px 12px;
  background-color: #ffebcb;
  margin-bottom: 10px;
  border-radius: 4px;

  color: #32383a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
