@import "../core/responsive/responsive.scss";

.fts-update-expired-password {
  height: calc(100vh - 90px);
  background-color: #F3F6F9;

  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F6F9;

    &-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 578px;
      width: 100%;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 3px 9px 0 #EDEDF3;
      padding: 40px;
      gap: 40px;

      .fts-icon {
        stroke: none;
        height: 160px;
        max-width: 498px;
        width: 100%;
      }

      .form-control {
        border-radius: 4px;
        color: #32383A;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.2px;
      }

      .eye-input-field {
        .fts-icon {
          stroke: none;
          height: 24px;
          width: 24px;
        }
        position: relative;
        .eye-button {
          position: absolute;
          right: -7px;
          top: 0;
          background-color: transparent !important;

          svg {
            margin-bottom: 4px !important;
          }
        }
        .eye-button:hover {
          background-color: transparent !important;
        }
        .eye-button:active  {
          background-color: transparent !important;
        }

        .eye-button:focus {
          background-color: transparent !important;
        }
      }

      .error-message {
        display: flex;
        align-items: center;
        border-radius: 4px;
        min-height: 48px;
        margin: 0;
        gap: 8px;
        padding: 12px;

        color: #32383A !important;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.2px;

        .fts-icon {
          stroke: #32383A;
          margin: 0 !important;
          width: 24px;
          height: 24px;
        }

        span {
          margin: 0 !important;
          margin-top: 1px !important;
        }
      }

      .error-message {
        border: 1px solid #F36565;
        background: #FFD7D7 !important;
      }

      h3 {
        margin-bottom: 8px;
        color: #32383A;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
      }

      p {
        margin-bottom: 0;
        color: #32383A;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }

      .btn-primary {
        color: #32383A;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height:  19.2px;
        height: 48px;
      }
    }
  }
}

@include target-breakpoint-and-smaller(sm) {
  .fts-update-expired-password {
  height: 100%;

    &-content {
      align-items: start;
      &-box {
        padding: 24px;

        .fts-icon {
          height: 176px;
          width: 100%;
        }

        h3 {
          font-size: 32px;
          line-height: 38.4px;
        }

        p {
          font-size: 20px;
          line-height: 26px;
        }

        .btn-primary {
          font-size: 14px;
          line-height: 17px;
          height: 48px;
        }
      }
    }
  }
}
