.FTS-password-expired-wrapper {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FTS-password-expired {
  max-width: 600px;
  height: 100%;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;

  color: #32383a;
  font-weight: 500;

  h2 {
    font-size: 54px;
    font-weight: 700;

    font-weight: bold;
    margin: 0;
  }

  a {
    color: #32383a;
    text-decoration: underline;
    cursor: pointer;
  }

  &-expiry-message {
    margin-top: 56px;
    margin-bottom: 36px;
    background-color: #e5e8e9;
    border-radius: 4px;
    font-size: 16px;
    line-height: 18px;
    padding: 16px 24px 16px 24px;
  }

  &-message {
    padding-bottom: 40px;
    margin: 0;
  }

  &-message:last-of-type {
    border-bottom: 1px solid #e0e5eb;
  }

  &-contact-us {
    font-size: 16px;
    line-height: 18px;
    padding-top: 16px;
  }
}

@media (max-width: 768px) {
  .FTS-password-expired {
    padding-top: 32px;
    width: 90vw;

    h2 {
      font-size: 24px;
      line-height: 28px;
    }

    &-expiry-message {
      margin-top: 32px;
    }

    &-message {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    &-message:last-of-type {
      padding-bottom: 20px;
    }
  }
}
