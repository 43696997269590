.fts-bar {
  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .action-bar {
    display: flex;
    align-items: center;
    height: 64px !important;
    margin-bottom: -2.6rem;
    margin-top: 12px;
    background-color: #fafafc;
    margin-left: -2.25rem;
    margin-right: -2.3rem;
    padding-left: 1.65rem;
    padding-right: 1.65rem;
  }

  .action-bar-free {
    margin-top: 12px;
    background-color: #fff;
    padding: 8px 40px;
    margin-left: -1.68rem;
    margin-right: -1.68rem;
    box-shadow: 0px 3px 9px #ededf3;
    margin-top: 0 !important;
  }

  .action-bar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 48px;
    color: #2c2f36;
    font-size: 14px;

    line-height: 21px;

    svg {
      margin-right: 0.5rem;
    }
  }

  .action-bar-button-outline {
    border: 1px solid #01b5f5;
    color: #01b5f5;
    border-radius: 4px;

    svg {
      stroke: #01b5f5;
    }
  }

  .action-bar-button:not(:last-child) {
    border-right: 1px solid #f5f5f8;
  }

  .action-bar-button:hover {
    background-color: #eff3f8;
    cursor: pointer;
  }

  .item-selected,
  .item-selected:hover {
    background-color: #57c990;
    color: #fff;
    border-radius: 4px;

    .fts-icon path {
      stroke: none;
    }
  }
}

.action-bar-paid {
  min-height: 76px;
}

@media (max-width: 1025px) {
  .collapse-button {
    display: none !important;
  }

  .fts-bar {
    display: none;
  }

  .action-bar-paid {
    display: none;
  }
}
